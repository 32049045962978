interface IIcon {
  width: string;
  height: string;
}

export const IconHeadset = ({ width, height }: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 18.3848V17.3845H14.8845C15.064 17.3845 15.2115 17.3268 15.327 17.2115C15.4423 17.0962 15.5 16.9488 15.5 16.7693V16H12.423V9.84625H15.5V8C15.5 6.06667 14.8167 4.41667 13.45 3.05C12.0833 1.68333 10.4333 1 8.5 1C6.56667 1 4.91667 1.68333 3.55 3.05C2.18333 4.41667 1.5 6.06667 1.5 8V9.84625H4.577V16H2.1155C1.65517 16 1.27083 15.8458 0.9625 15.5375C0.654167 15.2292 0.5 14.8448 0.5 14.3845V8C0.5 6.89483 0.708667 5.85733 1.126 4.8875C1.54333 3.91767 2.11542 3.06925 2.84225 2.34225C3.56925 1.61542 4.41767 1.04333 5.3875 0.625999C6.35733 0.208666 7.39483 0 8.5 0C9.60517 0 10.6427 0.208666 11.6125 0.625999C12.5823 1.04333 13.4307 1.61542 14.1578 2.34225C14.8846 3.06925 15.4567 3.91767 15.874 4.8875C16.2913 5.85733 16.5 6.89483 16.5 8V16.7693C16.5 17.2294 16.3458 17.6137 16.0375 17.922C15.7292 18.2305 15.3448 18.3848 14.8845 18.3848H8.5ZM2.1155 15H3.577V10.8463H1.5V14.3845C1.5 14.564 1.55767 14.7115 1.673 14.827C1.7885 14.9423 1.936 15 2.1155 15ZM13.423 15H15.5V10.8463H13.423V15Z"
      fill="#E37278"
    />
  </svg>
);
