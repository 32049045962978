import React from "react";
import * as Styled from "../styles";
import Button from "@components/common/Button";
import RadioSelect from "@components/Sales/Inputs/RadioSelect";
import InputSearch from "@components/Sales/Inputs/InputSearch";
import Table from "@components/Sales/Table";
import { documentMask, phoneMask } from "@helpers/format";
import Notify from "@components/Sales/Notify";
import { ISearchClientsComponent } from "src/@types/Sales";
import { UsuallyContext } from "@context/usuallyContex";
import salesService from "@services/Sales/SalesService";
import { isEmail } from "@helpers/emailCheck";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import QuestionsCreateAccountComponent from "../components/QuestionsCreateAccountComponent";
import { OportunityContext } from "@context/oportunityContex";
import { Box, Flex, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { IconLupaError } from "src/assets/IconLupaError";

const SearchClientsComponent = ({
  navigation,
  navigationScreenMyOportunity,
  navigationMyOportunity,
  navigationCreateOportunity,
}: ISearchClientsComponent) => {
  const {
    setTypeAccount,
    successCreateAccountSales,
    setSuccessCreateAccountSales,
    nameCreateAccountSales,
    successCreateOportunity,
    setSuccessCreateOportunity,
    nameCreateOportunity,
    accountIdSalesforce,
  } = React.useContext(UsuallyContext);
  const {
    setOrigemConta,
    setAccessCreateOp,
    setOnboardingDocuments,
    setContracts,
    homeCreateAccount,
    setHomeCreateAccount,
  } = React.useContext(OportunityContext);
  const { signOut } = React.useContext(LoginContext);
  const [search, setSearch] = React.useState("");
  const [dataSearchSales, setDataSearchSales] = React.useState([]);
  const [showCreateNewAccount, setShowCreateNewAccount] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [disabledSearchButton, setDisabledSearchButton] = React.useState(false);
  const [newSearch, setNewSearch] = React.useState("");
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);

  const [selectRadio, setSelectRadio] = React.useState("Email");
  const radioTel = "Telefone";
  const radioEmail = "Email";
  const radioCpf = "CPFCNPJ";

  const handleOptionChangeSearch = (value: string) => {
    setSelectRadio(value);
    setSearch("");
  };

  const handleSelectOptionPlaceholder = () => {
    if (selectRadio === "Telefone") return "(XX) XXXXX-XXXX";
    if (selectRadio === "Email") return "teste@provedor.com";
    if (selectRadio === "CPFCNPJ")
      return "XXX.XXX.XXX-XX ou XX.XXX.XXX/XXXX-XX";
  };
  const handleSelectOptionOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): string => {
    if (selectRadio === "Telefone")
      return phoneMask(e.target.value?.replace(/\D/g, ""));
    if (selectRadio === "Email") return e.target.value;
    if (selectRadio === "CPFCNPJ")
      return documentMask(e.target.value?.replace(/\D/g, ""));
    else return "";
  };
  const handleSelectOptionMaxLength = () => {
    if (selectRadio === "Telefone") return 15;
    if (selectRadio === "Email") return undefined;
    if (selectRadio === "CPFCNPJ") return 18;
  };

  const handleCloseModal = () => {
    setHomeCreateAccount(false);
    setShowCreateNewAccount(false);
    setSuccessCreateAccountSales(false);
    setTypeAccount("");
  };

  async function handleNavigationOportunity() {
    setShowCreateNewAccount(false);
    setSuccessCreateAccountSales(false);
    setSuccessCreateOportunity(false);
    setTypeAccount("");
    setOnboardingDocuments([]);
    setContracts([]);
    navigationMyOportunity();
  }

  async function handleNavigationScreenMyOoportunity() {
    setShowCreateNewAccount(false);
    setSuccessCreateAccountSales(false);
    setSuccessCreateOportunity(false);
    setTypeAccount("");
    const idCorretor: any = localStorage.getItem("@idCorretor");
    setLoading(true);

    try {
      const oportunities = await salesService.getOportunity({
        idCorretorSalesforce: idCorretor,
      });
      const oportunity = oportunities.find(
        (o: any) => o.accountIdSalesforce === accountIdSalesforce
      );

      if (oportunity !== undefined) {
        setAccessCreateOp({
          oportunityIdSalesforce: oportunity.opportunityIdSalesforce,
          oportunidadeId: oportunity.oportunidadeId,
          nomeDaConta: oportunity.nomeDaConta,
        });
        setOnboardingDocuments([]);
        setContracts([]);
        navigationMyOportunity();
      } else {
        navigationScreenMyOportunity();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigationScreenMyOportunity();
    }
  }

  const handleSelectOptionGet = () => {
    if (selectRadio === radioTel) return { telefone: search };
    if (selectRadio === radioEmail) return { email: search };
    else return { CPFCNPJ: search };
  };

  const handleGetSales = async () => {
    setLoading(true);

    try {
      const response = await salesService.getSales(handleSelectOptionGet());

      if (response?.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        setDataSearchSales([]);
        return;
      }

      setDataSearchSales(response);
      setOrigemConta(response?.origemConta);
      setNewSearch(search);
      setLoading(false);
    } catch (error: any) {
      console.log("errorHandleGetSales", error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (selectRadio === radioTel) {
      setDisabledSearchButton(phoneMask(search)?.length < 15);
    }
    if (selectRadio === radioCpf) {
      const isCnpjComplete = documentMask(search)?.length === 18;

      setDisabledSearchButton(
        documentMask(search)?.length < 14 ||
          (documentMask(search)?.length > 14 && !isCnpjComplete)
      );
    }
    if (selectRadio === radioEmail) {
      setDisabledSearchButton(!isEmail(search));
    }
  }, [search, selectRadio]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamante para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Notify
        onOpen={
          showCreateNewAccount || successCreateAccountSales || homeCreateAccount
        }
        onClose={handleCloseModal}
      >
        <Styled.AreaQuestions>
          <Styled.ContainerTitleNotify>
            <span>
              {successCreateAccountSales
                ? "Conta criada com sucesso!"
                : "Criar nova conta"}
            </span>
          </Styled.ContainerTitleNotify>
          {successCreateAccountSales ? (
            <Styled.ContainerSuccess>
              <span>
                A conta de <strong>{nameCreateAccountSales}</strong> foi
                adicionada em nosso banco de dados e você já pode acessar pela
                oportunidade vinculada.
              </span>
              <Button
                title="ACESSAR OPORTUNIDADE"
                onClick={handleNavigationScreenMyOoportunity}
              />
            </Styled.ContainerSuccess>
          ) : (
            <QuestionsCreateAccountComponent
              setShowCreateNewAccount={setShowCreateNewAccount}
              navigation={navigation}
            />
          )}
        </Styled.AreaQuestions>
      </Notify>

      <Notify
        onOpen={successCreateOportunity}
        onClose={() => setSuccessCreateOportunity(false)}
      >
        <Styled.AreaQuestions>
          <Styled.ContainerTitleNotify>
            <span>Oportunidade criada com sucesso!</span>
          </Styled.ContainerTitleNotify>
          <Styled.ContainerSuccess>
            <span>
              A oportunidade de <b>{nameCreateOportunity}</b> foi criada em
              nosso sistema e você já pode acessar a sua oportunidade.
            </span>
            <Button
              title="ACESSAR OPORTUNIDADE"
              onClick={handleNavigationOportunity}
            />
          </Styled.ContainerSuccess>
        </Styled.AreaQuestions>
      </Notify>

      <Flex
        w={"99%"}
        h={"80px"}
        alignItems={"center"}
        mt={{ base: "20px", md: "0px" }}
      >
        <Box
          display={"flex"}
          flexDir={"column"}
          alignItems={"center"}
          w={"85%"}
        >
          <Text fontSize={28} fontWeight={"semibold"} color={theme.text}>
            Criar oportunidade
          </Text>
          <Text fontSize={14} color={theme.text50}>
            Para criar uma oportunidade, pesquise pelo e-mail, telefone ou
            CPF/CNPJ do cliente
          </Text>
        </Box>

        <Flex w={160} mr={15} gap={20}>
          <Button
            title={
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                Cadastrar cliente
              </Box>
            }
            onClick={() => {
              setShowCreateNewAccount(true);
              setTypeAccount("");
            }}
          />
        </Flex>
      </Flex>

      <Flex
        flexDir={"column"}
        w={"98%"}
        h={"160px"}
        bg={"white"}
        borderRadius={"8px"}
        p={"20px"}
        mt={"20px"}
      >
        <Flex w={280} h={"60px"} gap={"1px"}>
          <RadioSelect
            label="Telefone"
            checked={selectRadio === radioTel}
            onChange={() => handleOptionChangeSearch(radioTel)}
          />
          <RadioSelect
            label="E-mail"
            checked={selectRadio === radioEmail}
            onChange={() => handleOptionChangeSearch(radioEmail)}
          />
          <RadioSelect
            label="CPF/CNPJ"
            checked={selectRadio === radioCpf}
            onChange={() => handleOptionChangeSearch(radioCpf)}
          />
        </Flex>

        <Flex h={"50%"} w={{ base: "280px", md: "480px" }}>
          <InputSearch
            placeholder={handleSelectOptionPlaceholder()}
            value={search}
            onChange={(e) => setSearch(handleSelectOptionOnChange(e))}
            maxLength={handleSelectOptionMaxLength()}
            disabledButton={loading || disabledSearchButton}
            onClickButton={handleGetSales}
            loadingButton={loading}
          />
        </Flex>
      </Flex>

      <Flex flexDir={"column"} w={"99%"} h={"300px"} mt={"10px"}>
        {dataSearchSales?.length === 0 ||
          (dataSearchSales === undefined ? (
            <Flex
              flexDir={"column"}
              w={"99%"}
              h={"280px"}
              bg={"white"}
              borderRadius={"8px"}
            >
              {dataSearchSales === undefined && newSearch !== "" && (
                <Flex
                  flexDirection={"column"}
                  w={"100%"}
                  h={"100%"}
                  alignItems={"center"}
                >
                  <IconLupaError width="180px" height="180px" />
                  <Text
                    fontSize={"20px"}
                    fontWeight={"bold"}
                    color={theme.text}
                    textAlign={"center"}
                  >
                    Cliente não encontrado
                  </Text>

                  <Text
                    fontSize={"13px"}
                    color={theme.text50}
                    textAlign={"center"}
                    w={{ base: "280px", md: "480px" }}
                    mt={"8px"}
                  >
                    Verifique se os dados digitados estão corretos ou tente
                    outro parâmetro de busca. Se o cliente não estiver
                    cadastrado no sistema,{" "}
                    <strong
                      style={{
                        color: theme.primary,
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        setShowCreateNewAccount(true);
                        setTypeAccount("");
                      }}
                    >
                      faça um novo cadastro.
                    </strong>
                  </Text>
                </Flex>
              )}
            </Flex>
          ) : (
            <Flex
              flexDir={"column"}
              w={"99%"}
              h={"280px"}
              bg={"white"}
              borderRadius={"8px"}
              pt={5}
            >
              <Table
                data={dataSearchSales}
                navigation={() => navigationCreateOportunity()}
                navigationOportunity={() => navigationMyOportunity()}
                navigationScreenMyOportunity={() =>
                  handleNavigationScreenMyOoportunity()
                }
                onSuccess={(accountId, accountName) => {
                  const processedSearchSalesData = (
                    dataSearchSales as any[]
                  ).map((d) => {
                    if (accountName === d.nome) {
                      return {
                        ...d,
                        accountIdSalesforce: accountId,
                        tipoConta: "Cliente - Pessoa Física",
                        ehLead: false,
                        origemConta: "Oferta do Corretor",
                      };
                    } else {
                      return d;
                    }
                  });

                  setDataSearchSales(processedSearchSalesData as never[]);
                }}
              />
            </Flex>
          ))}
      </Flex>
    </>
  );
};

export default SearchClientsComponent;
