import React from "react";
import * as Styled from "./styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useClickOutside } from "@hook/useClickOutside";
import Tooltip from "@components/common/Tooltip";
import { Box, useRadio } from "@chakra-ui/react";
import { EqualInputSelect } from "@hook/useEqualCode";

interface Option {
  id?: any;
  idRelacionamentoComprador?: any;
  name?: any;
  nome?: any;
}

interface ISelect {
  title: string;
  label: string;
  error?: boolean;
  isSelected?: string;
  height?: string;
  widht?: string;
  widhtMobile?: string;
  disabled?: boolean;
  isRequirement?: boolean;
  isOportunity?: boolean;
  readOnly?: boolean;

  options: Option[];
  selectedOption: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  tooltip?: string;
  checkId?: string;
  ranking?: string | null;
}

interface IRadioButton {
  id: any;
  name: string;
  checked: any;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  titleDescription?: string;
  description?: string;
  ranking?: string | null;
}

export const InputRadioButton = ({
  id,
  name,
  checked,
  onChange,
  titleDescription,
  description,
  ranking,
}: IRadioButton) => {
  const { getInputProps, getRadioProps, getRootProps } = useRadio({
    name,
    id,
    value: name,
    onChange,
    isChecked: checked,
  });

  const inputProps = getInputProps();
  const checkboxProps = getRadioProps();
  const rootProps = getRootProps();

  return (
    <Box
      as="li"
      display="flex"
      alignItems="center"
      listStyleType="none"
      my="4px"
    >
      <EqualInputSelect
        id={id}
        name={name}
        checked={checked}
        checkboxProps={checkboxProps}
        inputProps={inputProps}
        rootProps={rootProps}
        titleDescription={titleDescription}
        description={description}
        ranking={ranking}
      />
    </Box>
  );
};

const InputSelect = ({
  title,
  label,
  error,
  isSelected,
  height,
  widht,
  widhtMobile,
  disabled,
  isRequirement,
  options,
  selectedOption,
  onChange,
  isOportunity,
  readOnly,
  tooltip,
  checkId,
  ranking = null,
}: ISelect) => {
  const wrapperRef: any = React.useRef(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [check, setCheck] = React.useState("");

  const determineName = (option: Option) => option.name || option.nome;

  const determineId = (option: Option) =>
    option.id || option.idRelacionamentoComprador;

  const isChecked = (option: Option) => {
    const combinedOptionValue = `${determineName(option)} - ${determineId(
      option
    )}`;

    return combinedOptionValue === `${selectedOption} - ${check || checkId}`;
  };

  useClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  React.useEffect(() => {
    if (isSelected) {
      setOpen(false);
    }
  }, [isSelected]);

  return (
    <Styled.Container ref={wrapperRef}>
      <Styled.ContainerTop>
        <Styled.Label>
          {isRequirement && <Styled.Requirement>*</Styled.Requirement>}
          {title}
        </Styled.Label>
        {tooltip && (
          <Tooltip text={tooltip}>
            <Styled.IconBsFillInfoCircleFill size="12px" />
          </Tooltip>
        )}
      </Styled.ContainerTop>
      <Styled.SelectButton
        isOportunity={isOportunity}
        disabled={disabled ?? readOnly}
        error={error}
        onClick={() => {
          if (readOnly) return null;
          else {
            setOpen(!open);
          }
        }}
      >
        <Styled.Title
          isSize={label.length > 20}
          isSelected={isSelected}
          disabled={readOnly}
        >
          {label}
        </Styled.Title>
        <Styled.ContainerIcon>
          <IoIosArrowUp />
          <IoIosArrowDown />
        </Styled.ContainerIcon>
      </Styled.SelectButton>

      {open && (
        <Styled.ListItems
          widht={widht}
          widhtMobile={widhtMobile}
          height={height}
          isOpen={disabled ? false : open}
        >
          {options.map((option, index) => (
            <InputRadioButton
              key={index}
              id={index}
              name={determineName(option)}
              checked={isChecked(option) || option.name === selectedOption}
              ranking={ranking}
              onChange={(e) => {
                setOpen(false);
                setCheck(determineId(option));
                onChange(e);
              }}
            />
          ))}
        </Styled.ListItems>
      )}
    </Styled.Container>
  );
};
export default InputSelect;
