import React from "react";
import * as Styled from "./styles";
import { OportunityContext } from "@context/oportunityContex";

interface IStep {
  title: string;
  icon: any;
  states: string[];
}

interface SteppProps {
  steps: IStep[];
  progressPercent: number;
  activeStep: string;
}

const Stepp: React.FC<SteppProps> = ({
  steps,
  activeStep,
  progressPercent,
}) => {
  const [completedSteps, setCompletedSteps] = React.useState<string[]>([]);
  const { setCurrentFase } = React.useContext(OportunityContext);

  React.useEffect(() => {
    const activeStepIndex = steps.findIndex((step) =>
      step.states.includes(activeStep?.toLowerCase())
    );

    if (activeStepIndex !== -1) {
      const updatedSteps = steps
        .slice(0, activeStepIndex + 1)
        .map((step) => step.title);
      setCompletedSteps(updatedSteps);
    } else {
      setCompletedSteps([]);
    }
  }, [activeStep, steps]);

  React.useEffect(() => {
    setCurrentFase(activeStep);
  }, [activeStep, setCurrentFase]);

  const activeStepIndex = steps.findIndex((step) =>
    step.states.includes(activeStep?.toLowerCase())
  );
  const progressPercentManual =
    activeStepIndex !== -1 ? ((activeStepIndex + 1) / steps.length) * 100 : 0;

  return (
    <Styled.StepperContainer>
      {steps.map((step, index) => (
        <Styled.StepContainer key={step.title}>
          <Styled.StepIndicator>
            <Styled.StepStatus
              error={activeStep?.toLowerCase()}
              active={step.title?.toLowerCase() === activeStep?.toLowerCase()}
              completed={completedSteps.includes(step.title)}
              incompleted={step.title === activeStep}
            >
              {index === steps.length - 1
                ? `${progressPercent ? progressPercent : progressPercentManual?.toFixed(0)}%`
                : step.icon}
            </Styled.StepStatus>
          </Styled.StepIndicator>

          <div>
            <Styled.StepTitle
              error={activeStep?.toLowerCase()}
              active={step.title?.toLowerCase() === activeStep?.toLowerCase()}
              completed={completedSteps.includes(step.title)}
              incompleted={step.title === activeStep}
            >
              {step.title}
            </Styled.StepTitle>
          </div>
        </Styled.StepContainer>
      ))}
      <Styled.ProgressContainer>
        <Styled.ProgressIndicator
          progress={progressPercent ? progressPercent : progressPercentManual}
          error={activeStep?.toLowerCase()}
        />
      </Styled.ProgressContainer>
    </Styled.StepperContainer>
  );
};

export default Stepp;
