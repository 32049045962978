import api from "@services/api";
import axios, { AxiosError } from "axios";
import {
  IConvertLeadSchema,
  ICreateOportunitySchema,
  ICreatePersonLegalSchema,
  ICreatePersonPhisycalSchema,
  IGetAccountByIdSchema,
  IGetOportunityByIdSchema,
  IGetOportunitySchema,
  IGetSalesSchema,
  IUpdateAccount,
  IUpdateAdress,
} from "src/@types/Sales";

class SalesService {
  async getSales({ email, telefone, CPFCNPJ }: IGetSalesSchema) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      email,
      telefone,
      CPFCNPJ,
    };

    const handleOption = () => {
      if (email === "email") return "email";
      if (telefone === "telefone") return "telefone";
      if (CPFCNPJ === "CPFCNPJ") return "CPFCNPJ";
    };

    const tipoChave = handleOption();
    const chave = email || telefone || CPFCNPJ;

    try {
      const response = await api.get("/conta/obter", {
        params: body,
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data
        .map((d: any) => ({ ...d, tipoChave, chave }))
        .filter((d: any) => d.leadConvertido !== true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async createPersonPhisycal({
    idCorretorSalesforce,
    tipoRegistro,
    tratamento,
    nomeDaConta,
    regional,
    regionalComercial,
    unidadeDeNegocio,
    telefonePrincipal,
    emailPrincipal,
    renda,
    telefoneComplementar,
    emailComplementar,
    campanha,
    melhorHorarioDeAtendimento,
    finalidadeDaCompra,
    detalhesPodeMorar,
    tipoDeTrabalho,
    numeroDeFilhos,
    observacoes,
    origemDaConta,
    empreendimentoDeInteressePrincipal,
    numeroDeQuartos,
    numeroDeVagas,
    telefoneComplementarEhWhatsapp,
    telefoneComplementarEhCelular,
    telefonePrincipalEhWhatsapp,
    telefonePrincipalEhCelular,
    cpf,
    dataExpedicaoRG,
    dataNascimento,
    estadoCivil,
    nacionalidade,
    orgaoExpedidorRG,
    profissao,
    rg,
    criarOportunidade,
    sexo,
    dataCasamento,
    regimeCasamento,
  }: ICreatePersonPhisycalSchema) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      idCorretorSalesforce,
      tipoRegistro,
      origemDaConta,
      tratamento,
      nomeDaConta,
      regional,
      regionalComercial,
      unidadeDeNegocio,
      telefonePrincipal,
      emailPrincipal,
      renda,
      telefoneComplementar,
      emailComplementar,
      campanha,
      melhorHorarioDeAtendimento,
      finalidadeDaCompra,
      detalhesPodeMorar,
      tipoDeTrabalho,
      numeroDeFilhos,
      observacoes,
      empreendimentoDeInteressePrincipal,
      numeroDeQuartos,
      numeroDeVagas,
      telefoneComplementarEhWhatsapp,
      telefoneComplementarEhCelular,
      telefonePrincipalEhWhatsapp,
      telefonePrincipalEhCelular,
      cpf,
      dataExpedicaoRG,
      dataNascimento,
      estadoCivil,
      nacionalidade,
      orgaoExpedidorRG,
      profissao,
      rg,
      criarOportunidade,
      sexo,
      dataCasamento,
      regimeCasamento,
    };

    try {
      const response = await api.post("/conta/criar-pessoa-fisica", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async createPersonLegal({
    idCorretorSalesforce,
    tipoRegistro,
    nomeDaConta,
    nomeFantasia,
    regional,
    inscricaoEstadual,
    inscricaoMunicipal,
    cnpj,
    nire,
    regionalComercial,
    unidadeDeNegocio,
    telefonePrincipal,
    emailPrincipal,
    dataDeFundacao,
    detalhesPodeMorar,
    emailComplementar,
    finalidadeDaCompra,
    tipoDeTrabalho,
    observacoes,
    telefonePrincipalEhWhatsapp,
    telefonePrincipalEhCelular,

    origemDaConta,
    empreendimentoDeInteressePrincipal,
    numeroDeQuartos,
    numeroDeVagas,
    criarOportunidade,
  }: ICreatePersonLegalSchema) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      idCorretorSalesforce,
      tipoRegistro,
      nomeDaConta,
      nomeFantasia,
      regional,
      inscricaoEstadual,
      inscricaoMunicipal,
      cnpj,
      nire,
      regionalComercial,
      unidadeDeNegocio,
      telefonePrincipal,
      emailPrincipal,
      dataDeFundacao,
      detalhesPodeMorar,
      emailComplementar,
      finalidadeDaCompra,
      tipoDeTrabalho,
      observacoes,

      telefonePrincipalEhWhatsapp,
      telefonePrincipalEhCelular,

      origemDaConta,
      empreendimentoDeInteressePrincipal,
      numeroDeQuartos,
      numeroDeVagas,
      criarOportunidade,
    };

    try {
      const response = await api.post("/conta/criar-pessoa-juridica", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getCampaign() {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/conta/obter-campanhas", {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }

  async createOportunity({
    regional,
    accountIdSalesforce,
    idCorretorSalesforce,
    nomeDaConta,
    nomeCorretor,
    telefonePrincipal,
    dataNascimento,
    nacionalidade,
    emailPrincipal,
    emailComplementar,
    faixaEtaria,
    sexo,
    empreendimentoDeInteressePrincipal,
    campanha,
    fase,
    origemConta,
    telefoneComplementar,
    regionalComercial,
  }: ICreateOportunitySchema) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      regional,
      accountIdSalesforce,
      idCorretorSalesforce,
      nomeDaConta,
      nomeCorretor,
      telefonePrincipal,
      dataNascimento,
      nacionalidade,
      emailPrincipal,
      emailComplementar,
      faixaEtaria,
      sexo,
      empreendimentoDeInteressePrincipal,
      campanha,
      fase,
      origemConta,
      telefoneComplementar,
      regionalComercial,
    };

    try {
      const response = await api.post("/oportunidade/inserir", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getOportunity({
    idCorretorSalesforce,
    DataInicio,
    DataFim,
    FlagNovoConceito,
    filtro,
  }: IGetOportunitySchema) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/oportunidade/obter", {
        params: {
          idCorretorSalesforce,
          DataInicio,
          DataFim,
          FlagNovoConceito,
          filtro,
        },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getAccountById({ accountIdSalesforce }: IGetAccountByIdSchema) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/conta/obter-por-id", {
        params: { accountIdSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async updateAccount({
    AccountIdSalesforce,
    CPFCNPJ,
    DataExpedicaoRG,
    DataNascimento,
    EstadoCivil,
    Nacionalidade,
    Nome,
    OrgaoExpedidorRG,
    Profissao,
    RG,
    DataCasamento,
    RegimeCasamento,
    Email,
    regionalComercial,
    oportunidadeIdSalesforce,
  }: IUpdateAccount) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      AccountIdSalesforce,
      CPFCNPJ,
      DataExpedicaoRG,
      DataNascimento,
      EstadoCivil,
      Nacionalidade,
      Nome,
      OrgaoExpedidorRG,
      Profissao,
      RG,
      DataCasamento,
      RegimeCasamento,
      Email,
      regionalComercial,
      oportunidadeIdSalesforce,
    };

    try {
      const response = await api.patch("/conta/atualizar", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getOportunityById({ OportunidadeId }: IGetOportunityByIdSchema) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/oportunidade/obter-por-id", {
        params: { OportunidadeId },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async convertLead({
    leadId,
    telefone,
    email,
    nome,
    idCorretorSalesforce,
  }: IConvertLeadSchema) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const res = await api.post<{ accountId: string }>(
        "/lead/converter-lead",
        { leadId, telefone, email, idCorretorSalesforce },
        {
          headers: { access_token: `${access_token}` },
        }
      );

      return {
        success: true,
        nome,
        accountId: res.data.accountId,
        duplicated: false,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        return {
          success: false,
          nome,
          accountId: "",
          duplicated: error.response?.data?.duplicado,
        };
      } else {
        return { success: false, nome, accountId: "", duplicated: false };
      }
    }
  }

  async updateAdress(data: IUpdateAdress[]) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.patch("/endereco-conta/atualizar", data, {
        // const response = await api.post("/endereco-conta/inserir", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
}

const salesServiceInstance = new SalesService();
export default salesServiceInstance;
