interface IIcon {
  width: string;
  height: string;
}

export const IconInfo = ({ width, height }: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 13.4615C9.67433 13.4615 9.8205 13.4025 9.9385 13.2845C10.0565 13.1667 10.1155 13.0206 10.1155 12.8462C10.1155 12.6717 10.0565 12.5256 9.9385 12.4078C9.8205 12.2898 9.67433 12.2308 9.5 12.2308C9.32567 12.2308 9.1795 12.2898 9.0615 12.4078C8.9435 12.5256 8.8845 12.6717 8.8845 12.8462C8.8845 13.0206 8.9435 13.1667 9.0615 13.2845C9.1795 13.4025 9.32567 13.4615 9.5 13.4615ZM9 10.1538H10V4.15375H9V10.1538ZM9.50325 18C8.25875 18 7.08867 17.7638 5.993 17.2915C4.8975 16.8192 3.9445 16.1782 3.134 15.3685C2.3235 14.5588 1.68192 13.6067 1.20925 12.512C0.736417 11.4175 0.5 10.2479 0.5 9.00325C0.5 7.75875 0.736167 6.58867 1.2085 5.493C1.68083 4.3975 2.32183 3.4445 3.1315 2.634C3.94117 1.8235 4.89333 1.18192 5.988 0.70925C7.0825 0.236417 8.25208 0 9.49675 0C10.7413 0 11.9113 0.236167 13.007 0.708501C14.1025 1.18083 15.0555 1.82183 15.866 2.6315C16.6765 3.44117 17.3181 4.39333 17.7908 5.488C18.2636 6.5825 18.5 7.75208 18.5 8.99675C18.5 10.2413 18.2638 11.4113 17.7915 12.507C17.3192 13.6025 16.6782 14.5555 15.8685 15.366C15.0588 16.1765 14.1067 16.8181 13.012 17.2908C11.9175 17.7636 10.7479 18 9.50325 18ZM9.5 17C11.7333 17 13.625 16.225 15.175 14.675C16.725 13.125 17.5 11.2333 17.5 9C17.5 6.76667 16.725 4.875 15.175 3.325C13.625 1.775 11.7333 1 9.5 1C7.26667 1 5.375 1.775 3.825 3.325C2.275 4.875 1.5 6.76667 1.5 9C1.5 11.2333 2.275 13.125 3.825 14.675C5.375 16.225 7.26667 17 9.5 17Z"
      fill="#E37278"
    />
  </svg>
);
