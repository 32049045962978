import React from "react";
import {
  Box,
  Table as CKTable,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import * as Styled from "./styles";
import { UsuallyContext } from "@context/usuallyContex";
import { OportunityContext } from "@context/oportunityContex";
import oportunityService from "@services/Oportunity/OportunityService";
import { FaAward, FaSortDown, FaSortUp } from "react-icons/fa";
import { theme } from "src/styles/theme";

type TableRow = {
  id: string;
  fase: string;
  status: string;
  nomeDaConta: string;
  nomeDaUnidade: string;
  oportunidadeId: string;
  opportunityIdSalesforce: string;
  idEmpreendimentoSales: string;
  nomeEmpreendimento: string;
  imobiliaria: string;
  imobiliariaId: string;
  ranking: string | null;
};

interface IDataTable {
  data: TableRow[];
  onClick: () => void;
  searchFilter: string;
  fase: string;
}

const phaseOrder = [
  "Aguardando atendimento Corretor",
  "Em atendimento",
  "Visita Agendada",
  "Visita Realizada",
  "Em Análise de Crédito",
  "Análise de Crédito Realizada",
  "Proposta",
  "Proposta Reprovada",
  "Proposta Aprovada",
  "Análise SAFI",
  "Aprovado SAFI",
  "Rejeitado SAFI",
  "Contrato gerado",
  "Contrato comunicado",
  "Contrato com pendência comercial",
  "Fechado e ganho",
  "Repassado",
  "Cancelado",
  "Distratado",
  "Fechado e perdido",
];

function TableMyOportunity({
  data,
  onClick,
  searchFilter,
  fase,
}: Readonly<IDataTable>) {
  const { setHashSalesSidebar } = React.useContext(UsuallyContext);
  const {
    setLoading,
    // setOnboardingDocuments,
    setContracts,
    setAccessCreateOp,
    setFaseOp,
    setImobiliariaId,
    setImobiliariaNome,
    setIdEmpreendimentoSales,
    setNomeEmpreendimentoSales,
  } = React.useContext(OportunityContext);

  const [sortConfig, setSortConfig] = React.useState<{
    key: keyof TableRow;
    direction: "ascending" | "descending";
  }>({ key: "fase", direction: "ascending" });

  const filteredRows = React.useMemo(() => {
    const phaseGroups = {
      "Em Análise de Crédito": [
        "Em Análise de Crédito",
        "Análise de Crédito Realizada",
      ],
      Proposta: ["Proposta", "Proposta Aprovada", "Proposta Reprovada"],
      // "Contrato": [
      //   "Enviado aprovação de comissões",
      //   "Reprovado comissões",
      //   "Aprovado comissões",
      //   "Enviado aprovação de pro soluto",
      //   "Aprovado pro soluto",
      //   "Rejeitado pro soluto",
      //   "Contrato gerado",
      //   "Contrato comunicado",
      //   "Contrato com pendência comercial",
      // ],
    };

    if (!data) return [];

    let filteredData = data.filter((row) => {
      const lowerCaseFilter = searchFilter.toLowerCase();
      const isMatchingSearch =
        row.nomeDaConta?.toLowerCase().includes(lowerCaseFilter) ||
        row.oportunidadeId?.toLowerCase().includes(lowerCaseFilter) ||
        row.nomeEmpreendimento?.toLowerCase().includes(lowerCaseFilter) ||
        row.nomeDaUnidade?.toLowerCase().includes(lowerCaseFilter);

      if (
        fase?.toLowerCase()?.includes("sla vencida") ||
        // fase === "Cotações aprovadas sem FID"
        fase === "OP's Cliente Ouro" ||
        fase?.toLowerCase()?.includes("contrato")
      ) {
        return isMatchingSearch;
      } else {
        const isMatchingPhase =
          fase === "" ||
          fase === "Todas" ||
          row.fase?.toLowerCase() === fase.toLowerCase() ||
          Object.entries(phaseGroups).some(
            ([group, phases]) =>
              fase.toLowerCase() === group.toLowerCase() &&
              phases.some(
                (phase) => phase.toLowerCase() === row.fase?.toLowerCase()
              )
          );

        return isMatchingSearch && isMatchingPhase;
      }
    });

    // Remover duplicatas antes da ordenação
    filteredData = Array.from(
      new Set(filteredData.map((row) => row.oportunidadeId))
    )
      .map((id) => filteredData.find((row) => row.oportunidadeId === id))
      .filter(Boolean) as TableRow[]; // Remove undefined valores e garante que o tipo seja TableRow[]

    if (sortConfig !== null) {
      filteredData = filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key] || "";
        const bValue = b[sortConfig.key] || "";

        if (aValue === "" && bValue !== "") {
          return 1;
        }
        if (aValue !== "" && bValue === "") {
          return -1;
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, fase, searchFilter, sortConfig]);

  const requestSort = (key: keyof TableRow) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleClickId = (
    opportunityIdSalesforce: string,
    oportunidadeId: string,
    oportunidadeName: string
  ) => {
    setAccessCreateOp({
      oportunidadeId: oportunidadeId,
      nomeDaConta: oportunidadeName,
      oportunityIdSalesforce: opportunityIdSalesforce,
    });
    setLoading(true);

    oportunityService
      .getContracts({ opportunityIdSalesforce: opportunityIdSalesforce })
      .then((c) => {
        setContracts(c);
        setLoading(false);
      });
    onClick();
  };

  const getHeaderClass = (key: keyof TableRow) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === key
      ? sortConfig.direction === "ascending"
        ? "sorted-asc"
        : "sorted-desc"
      : undefined;
  };

  const renderSortIcon = (key: keyof TableRow) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <Styled.Icons>
          <FaSortUp />
        </Styled.Icons>
      ) : (
        <Styled.Icons>
          <FaSortDown />
        </Styled.Icons>
      );
    }
    return null;
  };

  const handleAdjustFase = (value: string) => {
    if (value?.toLowerCase() === "aguardando atendimento corretor")
      return "Aguardando atendimento";

    return value;
  };

  const handleRanking = (ranking: string) => {
    if (ranking === "Ouro") return "#E69C08";
    else if (ranking === "Prata") return "#8F8F8F";
    else if (ranking === "Bronze") return "#D6763A";
    else return undefined;
  };

  return (
    <CKTable flexDirection="column" variant="simple" bg="white">
      <Thead
        h="60px"
        position="sticky"
        top="0"
        zIndex="1"
        bg="white"
        boxShadow="sm"
      >
        <Tr>
          <Th w="17%" textAlign="center" color={theme.text}>
            <Box display={"flex"} alignItems={"center"} gap={"4px"}>
              <Text
                alignItems={"center"}
                fontSize={11}
                className={getHeaderClass("nomeDaConta")}
                onClick={() => requestSort("nomeDaConta")}
                w={"fit-content"}
                cursor={"pointer"}
              >
                Nome do cliente{" "}
              </Text>
              {renderSortIcon("nomeDaConta")}
            </Box>
          </Th>
          <Th w="17%" textAlign="center" color={theme.text}>
            <Box display={"flex"} alignItems={"center"} gap={"4px"}>
              <Text
                alignItems={"center"}
                fontSize={11}
                className={getHeaderClass("oportunidadeId")}
                onClick={() => requestSort("oportunidadeId")}
                w={"fit-content"}
                cursor={"pointer"}
              >
                ID da oportunidade{" "}
              </Text>
              {renderSortIcon("oportunidadeId")}
            </Box>
          </Th>
          <Th w="17%" color={theme.text}>
            <Box display={"flex"} alignItems={"center"} gap={"4px"}>
              <Text
                alignItems={"center"}
                fontSize={11}
                className={getHeaderClass("fase")}
                onClick={() => requestSort("fase")}
                cursor={"pointer"}
                w={"fit-content"}
              >
                Fase{" "}
              </Text>
              {renderSortIcon("fase")}
            </Box>
          </Th>
          <Th w="17%" textAlign="center" color={theme.text}>
            <Box display={"flex"} alignItems={"center"} gap={"4px"}>
              <Text
                alignItems={"center"}
                fontSize={11}
                className={getHeaderClass("nomeEmpreendimento")}
                onClick={() => requestSort("nomeEmpreendimento")}
                w={"fit-content"}
                cursor={"pointer"}
              >
                Empreendimento{" "}
              </Text>
              {renderSortIcon("nomeEmpreendimento")}
            </Box>
          </Th>
          <Th w="17%" textAlign="center" color={theme.text}>
            <Box display={"flex"} alignItems={"center"} gap={"4px"}>
              <Text
                alignItems={"center"}
                fontSize={11}
                className={getHeaderClass("nomeDaUnidade")}
                onClick={() => requestSort("nomeDaUnidade")}
                w={"fit-content"}
                cursor={"pointer"}
              >
                Unidade{" "}
              </Text>
              {renderSortIcon("nomeDaUnidade")}
            </Box>
          </Th>
        </Tr>
      </Thead>

      <Tbody>
        {filteredRows?.map((row: any) => (
          <Tr
            key={row?.oportunidadeId}
            _hover={{ bg: "#E2E8F0" }}
            cursor={"pointer"}
            onClick={() => {
              handleClickId(
                row?.opportunityIdSalesforce,
                row?.oportunidadeId,
                row?.nomeDaConta
              );
              setFaseOp(
                row?.fase?.toLocaleLowerCase() === "fechado e ganho" ||
                  row?.fase?.toLocaleLowerCase() === "fechado e perdido"
              );
              setNomeEmpreendimentoSales(row?.nomeEmpreendimento);
              setIdEmpreendimentoSales(row?.idEmpreendimentoSales);
              setImobiliariaNome(row?.imobiliaria);
              setImobiliariaId(row?.imobiliariaId);
              setHashSalesSidebar("DetailOportunityComponent");
            }}
          >
            <Td w="17%" color={theme.text}>
              <Styled.ContainerNameAccount>
                <span>{row?.nomeDaConta?.toUpperCase()} </span>
                {row?.ranking !== null && (
                  <Box
                    display={"flex"}
                    w={"fit-content"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <FaAward
                      color={handleRanking(row?.ranking)}
                      fontSize={"16px"}
                    />
                  </Box>
                )}
              </Styled.ContainerNameAccount>
            </Td>

            <Td w="17%" color={theme.text50}>
              <Styled.ContainerIdOportunidade>
                <span>{row?.oportunidadeId}</span>
              </Styled.ContainerIdOportunidade>
            </Td>

            <Td w="17%">
              <Styled.ContainerFase
                fase={row?.fase?.toLowerCase()}
                onClick={() => {}}
              >
                <span>{handleAdjustFase(row.fase)}</span>
              </Styled.ContainerFase>
            </Td>

            <Td w="17%" color={theme.text50}>
              <Styled.ContainerEmpreendimento>
                <span>{row?.nomeEmpreendimento}</span>
              </Styled.ContainerEmpreendimento>
            </Td>

            <Td w="17%" color={theme.text50}>
              <Styled.ContainerUnidade>
                <span>{row?.nomeDaUnidade}</span>
              </Styled.ContainerUnidade>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </CKTable>
  );
}

export default TableMyOportunity;
