import React, { useState } from "react";
import { DayPicker, DateRange } from "react-day-picker";
import "react-day-picker/style.css";
import { ptBR } from "react-day-picker/locale";
import "./CalendarWrapper.css";

interface ICustomCalendar {
  selectedRange: DateRange;
  setSelectedRange: React.Dispatch<React.SetStateAction<DateRange>>;
}

const CustomCalendar = ({
  selectedRange,
  setSelectedRange,
}: ICustomCalendar) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const handleMonthChange = (month: Date) => {
    setCurrentMonth(month);
  };

  return (
    <>
      <DayPicker
        required
        showOutsideDays
        mode="range"
        locale={ptBR}
        month={currentMonth}
        onMonthChange={handleMonthChange}
        selected={selectedRange}
        onSelect={setSelectedRange}
      />
      {/* {selectedRange && (
        <p style={{ textAlign: 'center', marginTop: '16px', color: '#707070' }}>
          {selectedRange?.from && formatDateToPtBr(selectedRange?.from)} -{' '}
          {selectedRange?.to ? formatDateToPtBr(selectedRange?.to) : '...'}
        </p>
      )} */}
    </>
  );
};

export default CustomCalendar;
