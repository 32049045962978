import React from "react";
import { LoginContext } from "@context/loginContext";
import CurrentLogin from "./Steps/CurrentLogin";
import FirstLogin from "./Steps/FirstLogin";
import ForgotPassword from "../ForgotPassword";
import Logo from "../../assets/logo.svg";
import * as Styled from "./styles";
import { useLocation } from "react-router-dom";
import { IHashMapKeyString } from "src/@types/Login";
import { Flex, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { motion } from "framer-motion";

const Login = () => {
  const location = useLocation();
  const {
    hashLogin,
    setHashLogin,
    setErrorLogin,
    errorForgotPassword,
    setPageHelper,
  } = React.useContext(LoginContext);
  const [hash, setHash] = React.useState<string>("Login");
  const MotionFlex = motion(Flex);

  const handleNavigation = (newHash: string, newDirection: number) => {
    setHash(newHash);
    setErrorLogin(false);
  };

  const LoginHashMap: IHashMapKeyString = {
    Login: (
      <Flex flexDir={"column"}>
        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={"20px"}
        >
          <Text
            fontSize={"18px"}
            w={"fit-content"}
            color={theme.text}
            fontWeight={"bold"}
          >
            Faça seu login no Bora Vender
          </Text>
          <Text fontSize={"13px"} w={"fit-content"} color={theme.text}>
            Novo(a) por aqui?{" "}
            <Text
              as={"a"}
              color={theme.primary}
              fontWeight={"medium"}
              w={"fit-content"}
              cursor={"pointer"}
              onClick={() => handleNavigation("FirstAccess", 1)}
            >
              Ir para o primeiro acesso
            </Text>
          </Text>
        </Flex>
        <CurrentLogin
          onClickForgotPassword={() =>
            handleNavigation("Esqueci minha senha", 1)
          }
          onClickFirstLogin={() =>
            handleNavigation("FirstAccess", 1)
          }
        />
      </Flex>
    ),
    FirstAccess: (
      <Flex flexDir={"column"}>
        <FirstLogin onBack={() => handleNavigation("Login", -1)} />
      </Flex>
    ),
    "Esqueci minha senha": (
      <ForgotPassword onBack={() => handleNavigation("Login", -1)} />
    ),
  };

  React.useEffect(() => {
    if (errorForgotPassword) {
      handleNavigation("FirstAccess", 1);
      setHashLogin("FirstAccess");
    }
  }, [errorForgotPassword]);

  React.useEffect(() => {
    setPageHelper(hashLogin);
    if (location.state?.message) {
      handleNavigation("Login", -1);
    }
  }, [hashLogin, location.state?.message, setPageHelper]);

  return (
    <Styled.Container>
      <Styled.ContainerLeft>
        <img
          alt="background"
          src={require("../../assets/background-left.png")}
        />
      </Styled.ContainerLeft>

      <Styled.ContainerRight>
        <Flex
          flexDir={"column"}
          w={{ base: "100%", xl: "55%" }}
          h={"100%"}
          justify={"center"}
          align={"center"}
        >
          <Styled.ContainerRightLogo>
            <img src={Logo} alt="Logo" />
          </Styled.ContainerRightLogo>

            <Flex
              flexDir={"column"}
              w={"100%"}
              h={{
                base: "90%",
                md: "90%",
                lg: "85%",
                xl: "70%",
                "2xl": "55%",
              }}
              align={"center"}
            >
              {LoginHashMap[hash]}
            </Flex>
        </Flex>
      </Styled.ContainerRight>
    </Styled.Container>
  );
};

export default Login;
