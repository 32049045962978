interface IIcon {
  width: string;
  height: string;
}

export const IconSchedule = ({ width, height }: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1462 13.3538L13.8538 12.6463L10 8.79175V4H9V9.20775L13.1462 13.3538ZM9.50325 18C8.25875 18 7.08867 17.7638 5.993 17.2915C4.8975 16.8192 3.9445 16.1782 3.134 15.3685C2.3235 14.5588 1.68192 13.6067 1.20925 12.512C0.736417 11.4175 0.5 10.2479 0.5 9.00325C0.5 7.75875 0.736167 6.58867 1.2085 5.493C1.68083 4.3975 2.32183 3.4445 3.1315 2.634C3.94117 1.8235 4.89333 1.18192 5.988 0.70925C7.0825 0.236417 8.25208 0 9.49675 0C10.7413 0 11.9113 0.236167 13.007 0.708501C14.1025 1.18083 15.0555 1.82183 15.866 2.6315C16.6765 3.44117 17.3181 4.39333 17.7908 5.488C18.2636 6.5825 18.5 7.75208 18.5 8.99675C18.5 10.2413 18.2638 11.4113 17.7915 12.507C17.3192 13.6025 16.6782 14.5555 15.8685 15.366C15.0588 16.1765 14.1067 16.8181 13.012 17.2908C11.9175 17.7636 10.7479 18 9.50325 18ZM9.5 17C11.7167 17 13.6042 16.2208 15.1625 14.6625C16.7208 13.1042 17.5 11.2167 17.5 9C17.5 6.78333 16.7208 4.89583 15.1625 3.3375C13.6042 1.77917 11.7167 1 9.5 1C7.28333 1 5.39583 1.77917 3.8375 3.3375C2.27917 4.89583 1.5 6.78333 1.5 9C1.5 11.2167 2.27917 13.1042 3.8375 14.6625C5.39583 16.2208 7.28333 17 9.5 17Z"
      fill="#E37278"
    />
  </svg>
);
