import React from "react";
import * as Styled from "./styles";
import BreadCrumb from "@components/common/BreadCrumb";
import { LoginContext } from "@context/loginContext";
import { IHashMapKeyString } from "src/@types/Sales";
import SearchClientsComponent from "./Steps/SearchClientsComponent";
import { UsuallyContext } from "@context/usuallyContex";
import AccountsPhysicalComponents from "./Steps/CreateAccount/AccountsPhysicalComponents";
import AccountsLegalComponents from "./Steps/CreateAccount/AccountsLegalComponents";
import CreateMyOportunityComponent from "./Steps/Oportunity/CreateMyOportunityComponent";
import MyOportunityComponent from "./Steps/Oportunity/MyOportunityComponent";
import DetailOportunityComponent from "./Steps/Oportunity/DetailOportunityComponent";
import { OportunityContext } from "@context/oportunityContex";

const Sales = () => {
  const { openSidebar, setPageSidebar } = React.useContext(LoginContext);
  const {
    typeAccount,
    successCreateAccountSales,
    hashSalesSidebar,
    setHashSalesSidebar,
    hashDetailOportunity,
    setHashDetailOportunity,
    hashOportunity,
    setHashOportunity,
  } = React.useContext(UsuallyContext);
  const {
    accessCreditRating,
    setAccessCreateOp,
    accessCreateOp,
    setAccessCreditRating,
    accessQuote,
    setAccessQuote,
    setHomeCreateAccount,
    indexTabDetails,
    setIndexTabDetails,
  } = React.useContext(OportunityContext);

  const [breadcrumbItems, setBreadcrumbItems] = React.useState([
    {
      name: "Principal",
      link: "/",
      onClick: () => {
        setPageSidebar("Principal");
        localStorage.setItem("@option", "Principal");
      },
    },
    // {
    //   name: "Buscar clientes",
    //   link: "#",
    //   onClick: () => setHashOportunity("Oportunity"),
    // },
  ]);

  function handleNavigationSales() {
    setHashOportunity("Oportunity");
    setHashSalesSidebar("Oportunity");
    setHashDetailOportunity("DetailOportunity");
  }
  function handleNavigationMyOportunity() {
    setHashOportunity("MyOportunityComponent");
    setHashSalesSidebar("MyOportunityComponent");
    setHashDetailOportunity("DetailOportunity");
    setAccessCreateOp({
      nomeDaConta: "",
      oportunidadeId: "",
      oportunityIdSalesforce: "",
    });
  }
  function handleNavigationDetailOportunity() {
    setHashOportunity("DetailOportunityComponent");
    setHashSalesSidebar("DetailOportunityComponent");
    setAccessCreditRating({
      idAvaliacaoCreditoSalesforce: "",
      nome: "",
    });
    setAccessQuote({
      idCotacaoSalesforce: "",
      nomeDaCotacao: "",
    });
    setHashDetailOportunity("DetailOportunity");
  }

  const SalesHashMap: IHashMapKeyString = {
    Oportunity: (
      <SearchClientsComponent
        navigationMyOportunity={handleNavigationDetailOportunity}
        navigationScreenMyOportunity={handleNavigationMyOportunity}
        navigation={() => {
          if (typeAccount === "Fisica") {
            setHashOportunity("AccountsPhysical");
            setHashSalesSidebar("AccountsPhysical");
            setHomeCreateAccount(false);
          } else {
            setHashOportunity("AccountsLegal");
            setHashSalesSidebar("AccountsLegal");
            setHomeCreateAccount(false);
          }
        }}
        navigationCreateOportunity={() => {
          setHashOportunity("CreateMyOportunityComponent");
          setHashSalesSidebar("CreateMyOportunityComponent");
          setHomeCreateAccount(false);
        }}
      />
    ),
    AccountsPhysical: (
      <AccountsPhysicalComponents
        onBack={handleNavigationSales}
        setMandatoryCreateAccount={() => {}}
        setCnpjCreateAccount={() => {}}
        setCpfCreateAccount={() => {}}
      />
    ),
    AccountsLegal: (
      <AccountsLegalComponents
        onBack={handleNavigationSales}
        setMandatoryCreateAccount={() => {}}
        setCnpjCreateAccount={() => {}}
        setCpfCreateAccount={() => {}}
      />
    ),
    CreateMyOportunityComponent: (
      <CreateMyOportunityComponent
        onBack={handleNavigationSales}
        navigation={handleNavigationSales}
      />
    ),
    MyOportunityComponent: (
      <MyOportunityComponent
        navigation={() => {
          setHashOportunity("DetailOportunityComponent");
          setIndexTabDetails(0);
        }}
      />
    ),
    DetailOportunityComponent: (
      <DetailOportunityComponent
        hash={hashDetailOportunity}
        setHash={setHashDetailOportunity}
        navigationCreateAccount={handleNavigationSales}
        index={indexTabDetails}
        setIndex={setIndexTabDetails}
      />
    ),
  };

  React.useEffect(() => {
    if (successCreateAccountSales) {
      handleNavigationSales();
    }
  }, [successCreateAccountSales]);

  React.useEffect(() => {
    let newBreadcrumbItems = [
      {
        name: "Principal",
        link: "#",
        onClick: () => handleNavigationSales(),
        // link: "/",
        // onClick: () => {
        //   setPageSidebar("Principal");
        //   localStorage.setItem("@option", "Principal");
        // },
      },
    ];

    if (hashOportunity === "Oportunity") {
      newBreadcrumbItems.push({
        name: "Buscar clientes",
        link: "#",
        onClick: () => setHashOportunity("Oportunity"),
      });
    }
    if (
      hashOportunity === "AccountsPhysical" ||
      hashOportunity === "AccountsLegal"
    ) {
      newBreadcrumbItems.push({
        name: "Criar nova conta",
        link: "#",
        onClick: () => handleNavigationSales(),
      });
    }

    if (
      [
        "MyOportunityComponent",
        "CreateMyOportunityComponent",
        "DetailOportunityComponent",
      ].includes(hashOportunity) &&
      hashOportunity !== "Oportunity"
    ) {
      newBreadcrumbItems.push({
        name: "Minhas Oportunidades",
        link: "#",
        onClick: () => handleNavigationMyOportunity(),
      });
    }

    if (
      ["CreateMyOportunityComponent"].includes(hashOportunity) &&
      hashOportunity !== "Sales"
    ) {
      newBreadcrumbItems.push({
        name: "Criar oportunidade",
        link: "#",
        onClick: () => handleNavigationMyOportunity(),
      });
    }
    if (
      ["DetailOportunityComponent"].includes(hashOportunity) &&
      accessCreateOp.oportunidadeId &&
      hashOportunity !== "Oportunity"
    ) {
      newBreadcrumbItems.push({
        name: accessCreateOp.oportunidadeId,
        link: "#",
        onClick: () => {
          handleNavigationDetailOportunity();
          setAccessCreditRating({
            idAvaliacaoCreditoSalesforce: "",
            nome: "",
          });
        },
      });
    }
    if (
      ["DetailOportunityComponent"].includes(hashOportunity) &&
      accessCreditRating.nome &&
      hashOportunity !== "Oportunity"
    ) {
      newBreadcrumbItems.push({
        name: accessCreditRating.nome,
        link: "#",
        onClick: () => {
          handleNavigationDetailOportunity();
          setIndexTabDetails(3);
        },
      });
    }
    if (
      ["DetailOportunityComponent"].includes(hashOportunity) &&
      ["ViewQuotesComponent"].includes(hashDetailOportunity) &&
      accessQuote.nomeDaCotacao &&
      hashOportunity !== "Oportunity"
    ) {
      newBreadcrumbItems.push({
        name: accessQuote.nomeDaCotacao,
        link: "#",
        onClick: () => {
          handleNavigationDetailOportunity();
          setIndexTabDetails(4);
        },
      });
    }

    setBreadcrumbItems(newBreadcrumbItems);
  }, [
    hashOportunity,
    accessCreateOp,
    accessCreditRating,
    setPageSidebar,
    hashDetailOportunity,
    accessQuote.nomeDaCotacao,
    setAccessCreditRating,
  ]);

  React.useEffect(() => {
    if (hashSalesSidebar === "Oportunity") {
      setHashOportunity("Oportunity");
    }
    if (hashSalesSidebar === "MyOportunityComponent") {
      setHashOportunity("MyOportunityComponent");
    }
  }, [hashSalesSidebar]);

  return (
    <Styled.Area open={openSidebar}>
      <Styled.Container>
        <Styled.SubContainer>
          <Styled.ContainerTop>
            <BreadCrumb items={breadcrumbItems} />
          </Styled.ContainerTop>
          {SalesHashMap[hashOportunity]}
        </Styled.SubContainer>
      </Styled.Container>
    </Styled.Area>
  );
};

export default Sales;
