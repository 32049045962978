import api from "@services/api";
import axios from "axios";

interface IGetSchedule {
  dataInicio: string;
  dataFim: string;
}

class HomeService {
  async getScheduledByDate({ dataInicio, dataFim }: IGetSchedule) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      dataInicio,
      dataFim,
    };

    try {
      const response = await api.get("/home/obter-por-data", {
        params: body,
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getFixos() {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/home/obter-fixos", {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
}

const homeServiceInstance = new HomeService();
export default homeServiceInstance;
