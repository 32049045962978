import React from "react";
import * as Styled from "./styles";
import Button from "@components/common/Button";
import Logo from "../../../../assets/logo.svg";
import { BsCheck2Circle } from "react-icons/bs";
import { LoginContext } from "@context/loginContext";
import { capitalize } from "@helpers/format";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Spinner from "@components/common/Spinner";

interface IHashMapForgotPassowrd {
  [key: string]: React.ReactNode;
}

const SuccessUpdatePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, password } = location.state;
  const name: any = localStorage.getItem("@user");
  const { loadingLogin, signIn } = React.useContext(LoginContext);
  const hash = "SuccessAccess";

  const handleLogin = async () => {
    const data = { email, password };

    await signIn(data);
  };

  const CurrentForgotPassowrdHashMap: IHashMapForgotPassowrd = {
    SuccessAccess: (
      <Styled.BodyCurrentLogin>
        <Styled.ContainerSuccessAccessTitle>
          <BsCheck2Circle />
          <span>Muito bom, {capitalize(name)}</span>
        </Styled.ContainerSuccessAccessTitle>

        <Styled.ContainerSuccessAccessBody>
          <span>Sua senha foi alterada com sucesso!</span>

          <span>
            Agora você já pode fazer login e aproveitar todas as funcionalidades
            do portal Bora Vender.
          </span>

          <p>Mantenha sempre suas informações de perfil atualizadas.</p>
        </Styled.ContainerSuccessAccessBody>

        <Styled.ContainerButtonLogin>
          <Button
            title={loadingLogin ? <Spinner /> : "ACESSAR O PORTAL"}
            onClick={handleLogin}
            disabled={loadingLogin}
          />
        </Styled.ContainerButtonLogin>
      </Styled.BodyCurrentLogin>
    ),
  };

  return (
    <Styled.Area>
      <Styled.Container>
        <Styled.ContainerLeft>
          <img
            alt="background"
            src={require("../../../../assets/background-left.png")}
          />
        </Styled.ContainerLeft>

        <Styled.ContainerRight>
          <Styled.AreaRight>
            <Styled.ContainerRightLogo>
              <img src={Logo} alt="Logo" />
            </Styled.ContainerRightLogo>

            <Styled.BodyRight>
              {CurrentForgotPassowrdHashMap[hash]}
            </Styled.BodyRight>
          </Styled.AreaRight>
        </Styled.ContainerRight>
      </Styled.Container>
    </Styled.Area>
  );
};

export default SuccessUpdatePassword;
