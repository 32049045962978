import { BsFillInfoCircleFill } from "react-icons/bs";
import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

interface IAccordionBlock {
  filter: string;
}

function statusBackground(filter: string) {
  if (filter === "disponível") return theme.available;
  if (filter === "mirror - disponível para venda") return theme.available;
  if (filter === "mirror") return theme.gold;
  if (filter === "reservada") return theme.error;
  if (filter === "reservada aguardando revisão de proposta") return theme.error;
  if (filter === "bloqueada") return theme.text;
  if (filter === "distratado") return theme.text;
  if (filter === "doação") return theme.text;
  if (filter === "fora de venda") return theme.text;
  if (filter === "fora de venda - comercial") return theme.text;
  if (filter === "leilão") return theme.text;
  if (filter === "permutada") return theme.text;
  if (filter === "suspenso") return theme.text;
  if (filter === "vendida") return theme.disable;
  if (filter === "quitado") return theme.disable;
  return theme.primary;
}
function statusBackgroundOpacity(filter: string) {
  if (filter === "disponível") return theme.available50;
  if (filter === "mirror - disponível para venda") return theme.available50;
  if (filter === "mirror") return theme.gold50;
  if (filter === "reservada") return theme.error50;
  if (filter === "reservada aguardando revisão de proposta")
    return theme.error50;
  if (filter === "bloqueada") return theme.text50;
  if (filter === "distratado") return theme.text50;
  if (filter === "doação") return theme.text50;
  if (filter === "fora de venda") return theme.text50;
  if (filter === "fora de venda - comercial") return theme.text50;
  if (filter === "leilão") return theme.text50;
  if (filter === "permutada") return theme.text50;
  if (filter === "suspenso") return theme.text50;
  if (filter === "vendida") return theme.backgroundSecondary;
  if (filter === "quitado") return theme.backgroundSecondary;
  return theme.backgroundPrimary;
}

export const ContainerShowFilter = styled.div<IAccordionBlock>`
  display: flex;
  width: fit-content;
  min-width: 25px;
  padding: 3px;
  border-radius: 8px;
  justify-content: center;
  color: white;
  background: ${(props) => {
    if (props.filter === "Disponível") return theme.available;
    if (props.filter === "Mirror") return theme.gold;
    if (props.filter === "Reservada") return theme.error;
    if (props.filter === "Bloqueada") return theme.text;
    if (props.filter === "Vendida") return theme.disable;
    return theme.primary;
  }};
`;

export const ContainerBlock = styled.div<IAccordionBlock>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 140px;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  background: ${(props) => statusBackground(props.filter)};
`;

export const AreaBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 15px;
  width: 100%;
  height: 100%;
  margin-top: 6px;

  @media ${device.laptopXL} {
    grid-template-columns: repeat(6, 1fr);
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const AreaBlockBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const ContainerFloors = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 6px;

  > span {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const BlockTop = styled.div<IAccordionBlock>`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: ${(props) => statusBackground(props.filter)};

  > span {
    opacity: ${(props) => {
      if (props.filter === "Reservada") return 0.6;
      if (props.filter === "Bloqueada") return 0.6;
      return 1;
    }};
    font-size: 13px;
    font-weight: bold;
  }
`;
export const BlockTopExpand = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;

  > span {
    color: white;
    font-size: 13px;
    font-weight: bold;
  }
`;

export const Divider = styled.div`
  display: flex;
  width: 80%;
  height: 3px;
  background: white;
  border-radius: 8px;
`;

export const BlockBottom = styled.div<IAccordionBlock>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 30px;
  width: 100%;
  height: 100%;
  padding: 8px;
  background: ${(props) => statusBackgroundOpacity(props.filter)};

  > span {
    font-size: 10px;
    font-weight: bold;
  }
`;

export const BlockBottomExpand = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 15px;
  grid-column-gap: 40px;
  width: 100%;
  height: 100%;
  padding: 4px;
`;

export const ContainerGroupIcon = styled.div`
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  gap: 3px;

  > svg {
    width: fit-content;
    color: white;
    font-size: 16px;
  }
  > span {
    color: white;
    font-weight: bold;
    font-size: 14px;
  }
`;
export const ContainerGroupExpand = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 3px;

  > span {
    color: ${theme.text};
    font-weight: bold;
    font-size: 14px;
  }
`;
export const ContainerGroupExpandColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 3px;

  > span {
    color: ${theme.text};
    font-weight: bold;
    font-size: 14px;
  }
`;

export const ContainerGroupExpandColumnTooltip = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 3px;

  > span {
    display: flex;
    color: ${theme.text};
    font-weight: bold;
    font-size: 14px;
  }
`;

export const IconBsFillInfoCircleFill = styled(BsFillInfoCircleFill)`
  color: ${theme.text};
  margin-top: 2px;
  margin-left: 4px;
  width: fit-content;
`;
export const ContainerGroupExpandDesc = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 3px;
  margin-top: 6px;

  > span {
    color: ${theme.text};
    font-weight: bold;
    font-size: 14px;
  }
`;
export const ContainerGroupExpandButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`;
export const ButtonGroupExpand = styled.div<IAccordionBlock>`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 100px;
  padding: 6px;
  height: 30px;
  border-radius: 8px;
  background: ${(props) => statusBackground(props.filter)};

  > span {
    font-weight: bold;
    color: white;
  }
`;
