"use client";

import React, { startTransition } from "react";
import { Box, Flex, Text, useBreakpointValue, Icon } from "@chakra-ui/react";
import InputSearch from "@components/Sales/Inputs/InputSearch";
import salesService from "@services/Sales/SalesService";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import TableMyOportunity from "@components/Sales/TableMyOportunity";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import { optionFase, optionOportunity } from "@helpers/optionSelect";
import Spinner from "@components/common/Spinner";
import { formatDatePtBr } from "@helpers/format";
import InputText from "@components/Sales/Inputs/InputText";
import Button from "@components/common/Button";
import { RxUpdate } from "react-icons/rx";
import type { TableRow } from "src/@types/Sales";
import { theme } from "src/styles/theme";
import { OportunityContext } from "@context/oportunityContex";

const MyOportunityComponent = ({ navigation }: any) => {
  const idCorretor: any = localStorage.getItem("@idCorretor");
  const { signOut } = React.useContext(LoginContext);
  const { faseMyOportunity, setFaseMyOportunity } =
    React.useContext(OportunityContext);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [dataOportunity, setDataOportunity] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [newSearch, setNewSearch] = React.useState("");
  const [oportunity, setOportunity] = React.useState("");
  const [dateStart, setDateStart] = React.useState("");
  const [dateEnd, setDateEnd] = React.useState("");

  const isMobile = useBreakpointValue({ base: true, md: false });

  function handleFlagOportunity(oportunity: string) {
    if (oportunity === "Nova") return true;
    if (oportunity === "Antiga") return false;
    if (oportunity === "Todas") return null;
    return null;
  }

  const handleNumberFase = () => {
    if (faseMyOportunity?.toLowerCase()?.includes("sla vencida")) return 1;
    else if (
      faseMyOportunity?.toLowerCase()?.includes("aguardando atendimento")
    )
      return 2;
    else if (faseMyOportunity?.toLowerCase()?.includes("contrato")) return 4;
    else if (faseMyOportunity?.toLowerCase()?.includes("cliente ouro"))
      return 5;
    else return 3;
  };

  const handleGetOportunity = async () => {
    setLoading(true);
    const handleFilter =
      faseMyOportunity === "OPs com SLA vencida" ||
      faseMyOportunity === "OP's Cliente Ouro" ||
      // faseMyOportunity === "Cotações aprovadas sem FID" ||
      faseMyOportunity === "Contrato"
        ? handleNumberFase()
        : undefined;

    try {
      const response = await salesService.getOportunity({
        idCorretorSalesforce: idCorretor,
        DataInicio: dateStart,
        DataFim: dateEnd,
        FlagNovoConceito: handleFlagOportunity(oportunity),
        filtro: handleFilter,
      });

      if (response.status === 401) {
        setIsUnauthorized(true);
        setDataOportunity([]);
        setLoading(false);
        return;
      }
      if (response.status === 400) {
        setDataOportunity([]);
        setLoading(false);
        return;
      }

      setDataOportunity(response ? response : []);
      setNewSearch(search);
      setLoading(false);
    } catch (error) {
      console.log("errorHandleGetSales", error);
      setLoading(false);
    }
  };

  const filteredRows = React.useMemo(() => {
    if (!dataOportunity) return [];
    else if (
      faseMyOportunity?.toLowerCase()?.includes("sla vencida") ||
      faseMyOportunity === "OP's Cliente Ouro" ||
      // faseMyOportunity === "Cotações aprovadas sem FID" ||
      faseMyOportunity?.toLowerCase()?.includes("contrato")
    )
      return dataOportunity;
    else
      return dataOportunity?.filter(
        (row: TableRow) =>
          (row?.nomeDaConta
            ?.toLowerCase()
            ?.includes(newSearch?.toLowerCase()) ||
            row?.opportunityIdSalesforce
              ?.toLowerCase()
              ?.includes(newSearch?.toLowerCase())) &&
          (faseMyOportunity === "" ||
            faseMyOportunity === "Todas" ||
            row?.fase?.toLowerCase() === faseMyOportunity.toLowerCase())
      );
  }, [dataOportunity, faseMyOportunity, newSearch]);

  React.useEffect(() => {
    if (
      idCorretor &&
      ((dateStart.length === 10 && dateEnd.length === 10) ||
        (dateStart.length === 0 && dateEnd.length === 0))
    ) {
      handleGetOportunity();
    }
  }, [idCorretor, dateStart, dateEnd, oportunity, faseMyOportunity]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamante para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Flex
        w="99%"
        h="80px"
        alignItems="center"
        justifyContent="space-between"
        mt={{ base: "20px", md: "0px" }}
      >
        <Box
          display="flex"
          flexDir="column"
          alignItems={isMobile ? "flex-start" : "center"}
        >
          <Text fontSize={28} fontWeight="semibold" color={theme.text}>
            Minhas oportunidades
          </Text>
          <Text fontSize={14} color={theme.text50}>
            Selecione uma oportunidade para ver mais detalhes, criar
            agendamentos e mais
          </Text>
        </Box>

        <Flex w={140}>
          <Button
            title={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="4px"
              >
                <Icon fontSize="16px" color="white" as={RxUpdate} />
                Atualizar
              </Box>
            }
            onClick={() => handleGetOportunity()}
          />
        </Flex>
      </Flex>

      <Flex
        flexDir="column"
        w="99%"
        h={isMobile ? "auto" : "120px"}
        bg="white"
        borderRadius="8px"
        mt="8px"
        gap="6px"
        p="10px"
        alignItems="center"
        justifyContent="center"
      >
        <Flex>
          <Text fontWeight="semibold">Pesquisa e filtros</Text>
        </Flex>

        <Flex
          gap="6px"
          flexWrap={isMobile ? "wrap" : "nowrap"}
          flexDirection={isMobile ? "column" : "row"}
          width="100%"
        >
          <Box
            display="flex"
            width={isMobile ? "100%" : "80%"}
            flexDirection={isMobile ? "column" : "row"}
            alignItems={isMobile ? "baseline" : "center"}
          >
            <InputSearch
              isOportunity
              label="Nome do cliente ou ID da oportunidade"
              placeholder={`Pesquisar...`}
              value={search}
              onChange={(e) => {
                startTransition(() => {
                  setSearch(e.target.value);
                });
              }}
            />
          </Box>

          <Flex
            width={isMobile ? "100%" : "95%"}
            gap="6px"
            flexDirection={isMobile ? "column" : "row"}
          >
            <InputSelect
              widht="17%"
              widhtMobile="90%"
              title="Fase"
              label={faseMyOportunity || "Selecione"}
              isSelected={faseMyOportunity}
              options={optionFase}
              selectedOption={faseMyOportunity}
              onChange={(e) => setFaseMyOportunity(e.currentTarget.value)}
            />
            <InputSelect
              widht="17%"
              widhtMobile="90%"
              title="Oportunidade"
              label={oportunity || "Selecione"}
              isSelected={oportunity}
              options={optionOportunity}
              selectedOption={oportunity}
              onChange={(e) => setOportunity(e.currentTarget.value)}
            />
          </Flex>

          <Flex flexDirection="column" width={isMobile ? "100%" : "90%"}>
            <Text
              fontSize="12px"
              color={theme.text}
              marginLeft="5px"
              height="18px"
            >
              Filtrar por data
            </Text>
            <Flex gap="6px" flexDirection={isMobile ? "column" : "row"}>
              <InputText
                label=""
                name="dateStart"
                placeholder={`De __/__/___`}
                value={dateStart}
                onChange={(e) => setDateStart(formatDatePtBr(e.target.value))}
                maxLength={10}
                iconSee={false}
              />
              <InputText
                label=""
                name="dateEnd"
                placeholder={`Até __/__/___`}
                value={dateEnd}
                onChange={(e) => setDateEnd(formatDatePtBr(e.target.value))}
                maxLength={10}
                iconSee={false}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        flexDir="column"
        mt="12px"
        bg="white"
        borderRadius="8px"
        w="99%"
        h={{ base: "auto", xl: "320px", "2xl": "620px" }}
      >
        <>
          {loading ? (
            <Flex
              width="100%"
              height="350px"
              justifyContent="center"
              alignItems="center"
              sx={{
                "@media (min-width: 1200px)": {
                  height: "620px",
                },
              }}
            >
              <Spinner />
            </Flex>
          ) : (
            <Flex
              flexDir="column"
              w="100%"
              h="fit-content"
              overflow="auto"
              borderRadius="8px"
              css={{
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: theme.primary,
                  borderRadius: "20px",
                },
              }}
            >
              {filteredRows.length === 0 ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  width="98%"
                  border="1px solid"
                  borderColor={theme.disable100}
                  padding="5px"
                  margin="5px"
                  sx={{
                    "@media (min-width: 1200px)": {
                      padding: "8px",
                      width: "99%",
                    },
                    "@media (min-width: 768px)": {
                      width: "70%",
                    },
                    "@media (max-width: 425px)": {
                      width: "90%",
                    },
                  }}
                >
                  {filteredRows.length === 0 && newSearch === "" ? (
                    <>
                      <Text
                        display="flex"
                        fontSize="16px"
                        fontWeight="bold"
                        color={theme.text}
                      >
                        Pesquise utilizando as seguintes sugestões
                      </Text>

                      <Text display="flex" fontSize="12px" color={theme.text}>
                        - Selecione um tipo de pesquisa entre: Nome da conta ou
                        Id da oportunidade;
                      </Text>
                      <Text display="flex" fontSize="12px" color={theme.text}>
                        - Certifique-se de que os dados estejam digitados
                        corretamente;
                      </Text>
                      <Text display="flex" fontSize="12px" color={theme.text}>
                        - Tente escolher palavras-chave;
                      </Text>
                      <Text display="flex" fontSize="12px" color={theme.text}>
                        - Use termos mais genéricos, ou seja, com um conceito
                        mais amplo
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text
                        display="flex"
                        fontSize="12px"
                        fontWeight="bold"
                        color={theme.error}
                      >
                        Sua pesquisa por {newSearch} não correspondeu a nenhum
                        resultado.
                      </Text>

                      <Text display="flex" fontSize="12px" color={theme.text}>
                        Se preferir, pesquise novamente utilizando as seguintes
                        sugestões:
                      </Text>
                      <Text display="flex" fontSize="12px" color={theme.text}>
                        - Certifique-se de que todas as palavras estejam
                        digitadas corretamente;
                      </Text>
                      <Text display="flex" fontSize="12px" color={theme.text}>
                        - Tente escolher palavras-chave diferentes das que você
                        digitou;
                      </Text>
                      <Text display="flex" fontSize="12px" color={theme.text}>
                        - Use termos mais genéricos, ou seja, com um conceito
                        mais amplo.
                      </Text>
                    </>
                  )}
                </Box>
              ) : (
                <TableMyOportunity
                  data={dataOportunity}
                  searchFilter={search}
                  fase={faseMyOportunity}
                  onClick={navigation}
                />
              )}
            </Flex>
          )}
        </>
      </Flex>
    </>
  );
};

export default MyOportunityComponent;
