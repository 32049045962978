import React from "react";
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
  GridItem,
  Divider,
} from "@chakra-ui/react";
import Button from "@components/common/Button";
import { theme } from "src/styles/theme";
import Tab from "@components/Sales/Tab";
import { IconCalendar } from "src/assets/iconCalendar";
import CustomCalendar from "./CustomCalendar";
import CustomComitions from "./CustomComitions";
import { MdOutlineArrowOutward } from "react-icons/md";
import CustomResume from "./CustomResume";
import CustomOportunityByStatus from "./CustomOportunityByStatus";
import ButtonPopover from "@components/Sales/ButtonPopover";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "@context/loginContext";
import { UsuallyContext } from "@context/usuallyContex";
import { OportunityContext } from "@context/oportunityContex";
import { ICalendario, IFixos } from "src/@types/Home";
import { formatMoney } from "@helpers/format";
import CustomCreditRatingByStatus from "./CustomCreditRatingByStatus";
import homeServiceInstance from "@services/Home/homeService";
import Spinner from "@components/common/Spinner";
import { DateRange } from "react-day-picker";
import { ptBR } from "react-day-picker/locale";
import { format } from "date-fns";
import Alert from "@components/common/Alert";

const fixos = {
  oportunidadesPendentes: [
    {
      fase: "",
      quantidade: 0,
    },
  ],
  oportunidadesPorStatus: [
    {
      fase: "",
      quantidade: 0,
    },
  ],
  analiseDeCreditoPorStatus: [
    {
      status: "",
      quantidade: 0,
    },
  ],
  comissoes: [
    {
      statusPagamento: "",
      idOportunidade: "",
      cliente: "",
      dataDePagamento: "",
      valorHonorarios: 0,
    },
  ],
};

const calendario = {
  agendamentos: [
    {
      opportunityIdSalesforce: "",
      oportunidadeId: "",
      nomeCorretor: "",
      nomePDV: "",
      nomeEmpreendimento: "",
      gerenteRegional: "",
      idAgendamentoSalesforce: "",
      dataAgendamento: "",
      horaAgendamento: "",
      pdv: "",
      diretorDeVendas: "",
      relativoA: "",
      tipoDeVisita: "",
      codigoDoAgendamento: "",
      localizacaoDoStand: "",
      unidadeDeNegocio: "",
      regional: "",
      participantes: "",
      assunto: "",
      visitaRealizada: false,
      observacoes: "",
      dataDeComparecimento: "",
      imobiliaria: "",
      contactIdSalesforce: "",
      assuntoParaAgendamento: "",
      gerenteDeVendas: "",
      criadoPor: "",
    },
  ],
};

const Home: React.FC = () => {
  const user: any = localStorage.getItem("@user");
  const navigate = useNavigate();
  const { setPageSidebar, signOut } = React.useContext(LoginContext);
  const {
    setHashSalesSidebar,
    setTypeAccount,
    setHashOportunity,
    setHashDetailOportunity,
  } = React.useContext(UsuallyContext);
  const {
    setHomeCreateAccount,
    setAccessCreateOp,
    setIndexTabDetails,
    setIndexTabActivityComponent,
    setRedirectHome,
  } = React.useContext(OportunityContext);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [showValues, setShowValues] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [dataFixos, setDataFixos] = React.useState<IFixos>(fixos);
  const [dataCalendar, setDataCalendar] =
    React.useState<ICalendario>(calendario);
  const [loadingCalendar, setLoadingCalendar] = React.useState(false);
  const [selectedRange, setSelectedRange] = React.useState<DateRange>({
    from: new Date(),
    to: new Date(new Date().setDate(new Date().getDate() + 7)),
  });

  const formatNome = (name: string) => {
    const primeiroNome = name?.split(" ")[0]?.toLowerCase();
    return primeiroNome?.charAt(0)?.toUpperCase() + primeiroNome?.slice(1);
  };

  const formatDateToPtBr = (date: Date | undefined) => {
    return date ? format(date, "yyyy-MM-dd", { locale: ptBR }) : "";
  };

  const arrayButtons = [
    {
      title: `Criar Conta`,
      onClick: () => {
        navigate("/oportunidades");
        setPageSidebar("Criar oportunidade");
        localStorage.setItem("@option", "Criar Oportunidade");
        setHashSalesSidebar("Oportunity");
        setTypeAccount("");
        setHomeCreateAccount(true);
      },
    },
    {
      title: `Criar Oportunidade`,
      onClick: () => {
        navigate("/oportunidades");
        setPageSidebar("Criar oportunidade");
        localStorage.setItem("@option", "Criar Oportunidade");
        setHashSalesSidebar("Oportunity");
      },
    },
  ];

  const tabLabels = [
    "Oportunidades por status",
    "Avaliações de crédito por status",
  ];
  const tabContents = {
    "Oportunidades por status": (
      <>
        {/* Gráfico e legenda */}
        <CustomOportunityByStatus
          data={
            Array.isArray(dataFixos.oportunidadesPorStatus)
              ? dataFixos.oportunidadesPorStatus
              : []
          }
        />
      </>
    ),
    "Avaliações de crédito por status": (
      <>
        <CustomCreditRatingByStatus
          data={
            Array.isArray(dataFixos.analiseDeCreditoPorStatus)
              ? dataFixos.analiseDeCreditoPorStatus
              : []
          }
        />
      </>
    ),
  };

  const totalComission = dataFixos?.comissoes?.reduce(
    (sum, item) => sum + item.valorHonorarios,
    0
  );

  const handleRedirectSchedulle = (
    oportunidadeId: string,
    opportunityIdSalesforce: string
  ) => {
    setPageSidebar("Minhas oportunidades");
    localStorage.setItem("@option", "Minhas oportunidades");
    navigate("/oportunidades");

    setHashOportunity("DetailOportunityComponent");
    setHashDetailOportunity("DetailOportunity");
    setRedirectHome("schedule");
    setIndexTabDetails(0);
    setIndexTabActivityComponent(0);

    setAccessCreateOp((prevState: any) => ({
      ...prevState,
      oportunidadeId: oportunidadeId,
      oportunityIdSalesforce: opportunityIdSalesforce,
    }));
  };

  const handleGetFixos = async () => {
    setLoading(true);
    try {
      const response = await homeServiceInstance.getFixos();

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        setDataFixos(fixos);
        console.log("status 400", response);
        return;
      }

      if (response.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      setLoading(false);
      setDataFixos(response);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleGetFixos();
  }, []);

  React.useEffect(() => {
    const handleGetCalendar = async () => {
      setLoadingCalendar(true);
      try {
        const response = await homeServiceInstance.getScheduledByDate({
          dataInicio: formatDateToPtBr(selectedRange.from),
          dataFim: formatDateToPtBr(selectedRange.to),
        });

        if (response.status >= 400 && response?.status !== 401) {
          setLoadingCalendar(false);
          setDataCalendar(response);
          console.log("status 400", response);
          return;
        }

        if (response.status === 401) {
          setLoadingCalendar(false);
          // setIsUnauthorized(true);
          return;
        }

        setLoadingCalendar(false);
        setDataCalendar(response);
      } catch (error) {
        setLoadingCalendar(false);
        console.log(error);
      }
    };

    handleGetCalendar();
  }, [selectedRange.from, selectedRange.to]);

  return (
    <Box
      display={"flex"}
      pos={"relative"}
      flexDir={"column"}
      p={8}
      left={{ lg: "230px" }}
      mt={{ base: "20px", md: "15px", lg: "0px" }}
      w={{ lg: "calc(100% - 230px)" }}
      overflowX={"auto"}
      bg={theme.disable100}
      h={"100%"}
    >
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamante para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      {/* Header */}
      <Flex
        flexDir={{ base: "column", md: "row" }}
        w={"99%"}
        h={{ base: "fit-content", md: "80px" }}
        alignItems={"center"}
        mb={8}
      >
        <Box
          display={"flex"}
          flexDir={"column"}
          alignItems={"center"}
          w={{ base: "fit-content", md: "85%" }}
        >
          <Text fontSize={28} fontWeight={"semibold"} color={theme.text}>
            Olá, {formatNome(user)}
          </Text>
          <Text fontSize={14} color={theme.text50}>
            Confira um resumo das suas oportunidades, agendamentos e comissões
          </Text>
        </Box>

        <Flex w={"360px"} mr={15} gap={"10px"} mt={{ base: "10px", md: "0px" }}>
          <Button
            title={
              <Flex align={"center"} justify={"center"}>
                Diri Academy
                <Box fontSize={"18px"} w={"fit-content"}>
                  <MdOutlineArrowOutward />
                </Box>
              </Flex>
            }
            colorInverter
            onClick={() =>
              window.open("https://diriacademy.skore.io/login", "_blank")
            }
          />

          {/* <Button title={"Criar"} onClick={() => {}} /> */}

          <ButtonPopover
            title={"Criar"}
            popoverContent={arrayButtons.map((button) => (
              <Flex key={button.title} h={"40px"}>
                <Flex flexDir={"column"}>
                  <Text
                    fontSize={"13px"}
                    fontWeight={"medium"}
                    cursor={"pointer"}
                    color={theme.text}
                    onClick={button.onClick}
                  >
                    {button.title}
                  </Text>

                  <Divider h={2} color={theme.disable} mt={"5px"} />
                </Flex>
              </Flex>
            ))}
          />
        </Flex>
      </Flex>

      <Flex flexDir={"column"} align={"center"}>
        <Flex flexDir={"column"} w={{ base: "full", "2xl": "80rem" }}>
          {loading ? (
            <Flex h={"71vh"} align={"center"} justify={"center"}>
              <Spinner />
            </Flex>
          ) : (
            <Grid
              templateAreas={{
                base: `
            "resumo"
            "oportunidades"
            "comissoes"
            "agendamentos"
            "datas"
            "favoritos"
          `,
                md: `
            "agendamentos resumo resumo"
            "datas oportunidades oportunidades"
            "favoritos comissoes comissoes"
          `,
              }}
              gridTemplateColumns={{
                base: "1fr",
                md: "2fr 2fr 2fr",
                "2xl": "1fr 2fr 2fr",
              }}
              gap={6}
            >
              {/* Oportunidades pendentes */}
              <GridItem
                area="resumo"
                bg="white"
                p={6}
                borderRadius="lg"
                shadow="md"
                h={{ base: "auto", md: "180px" }}
              >
                <Text fontWeight={"semibold"} color={theme.text} mb={4}>
                  Oportunidades pendentes
                </Text>

                <CustomResume
                  data={
                    dataFixos.oportunidadesPendentes
                      ? dataFixos.oportunidadesPendentes
                      : []
                  }
                />
              </GridItem>

              {/* Oportunidades por status */}
              <GridItem
                area="oportunidades"
                bg="white"
                p={2}
                borderRadius="lg"
                shadow="md"
                mt={{ base: "0", md: "-150px" }}
              >
                <Box w="100%">
                  {/* Tabs */}
                  <Tab
                    widthXl="100%"
                    width="100%"
                    widthMd="100%"
                    widthSm="100%"
                    widthBase="100%"
                    index={index}
                    setIndex={setIndex}
                    tabLabels={tabLabels}
                    tabContents={tabContents}
                    // disabled={loadingGetOportunity}
                  />
                </Box>
              </GridItem>

              {/* Próximas Comissões */}
              <GridItem
                area="comissoes"
                bg="white"
                p={6}
                borderRadius="lg"
                shadow="md"
                h={{ base: "auto", md: "300px" }}
              >
                <Text
                  fontSize={"18px"}
                  fontWeight={"semibold"}
                  color={theme.text}
                >
                  Próximas comissões
                </Text>

                <Text mb={4} fontSize={"14px"} color={theme.text}>
                  Você tem um total de{" "}
                  <Text as="span" fontWeight="bold">
                    {showValues ? `${formatMoney(totalComission)}` : "R$ •••••"}
                  </Text>{" "}
                  a receber
                </Text>

                <Flex
                  overflowY={"auto"}
                  h={"200px"}
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#D3D3D3", // Cor do scroll
                      borderRadius: "10px", // Deixa arredondado
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#A0AEC0", // Cor quando o mouse passa
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#F7FAFC", // Fundo do track do scroll
                    },
                  }}
                >
                  <CustomComitions
                    data={
                      Array.isArray(dataFixos.comissoes)
                        ? dataFixos.comissoes
                        : []
                    }
                    showValues={showValues}
                    setShowValues={setShowValues}
                  />
                </Flex>
              </GridItem>

              {/* Calendario */}
              <GridItem
                display={"flex"}
                flexDir={"column"}
                area="agendamentos"
                bg="white"
                p={2}
                borderRadius="lg"
                shadow="md"
                w={{ base: "auto", xl: "auto", "2xl": "350px" }}
                h={{ base: "auto", md: "330px" }}
                overflowX={"auto"}
                css={{
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#D3D3D3", // Cor do scroll
                    borderRadius: "10px", // Deixa arredondado
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#A0AEC0", // Cor quando o mouse passa
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#F7FAFC", // Fundo do track do scroll
                  },
                }}
              >
                <CustomCalendar
                  selectedRange={selectedRange}
                  setSelectedRange={setSelectedRange}
                />
              </GridItem>

              {/* Agendamentos */}
              <GridItem
                area="datas"
                bg="white"
                p={8}
                borderRadius="lg"
                shadow="md"
              >
                <Heading as="h3" size="md" mb={4}>
                  Agendamentos
                </Heading>
                <VStack
                  overflowX={"auto"}
                  h={"110px"}
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#D3D3D3", // Cor do scroll
                      borderRadius: "10px", // Deixa arredondado
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#A0AEC0", // Cor quando o mouse passa
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#F7FAFC", // Fundo do track do scroll
                    },
                  }}
                >
                  {loadingCalendar ? (
                    <Flex h={"110px"} align={"center"} justify={"center"}>
                      <Spinner />
                    </Flex>
                  ) : (
                    <>
                      {dataCalendar.agendamentos.length === 0 ? (
                        <Flex align="center" justify="center" h={"190px"}>
                          <Text w={"fit-content"} color={theme.text50}>
                            Não há agendamentos nas datas selecionadas!
                          </Text>
                        </Flex>
                      ) : (
                        <>
                          {dataCalendar.agendamentos.map(
                            (
                              {
                                dataAgendamento,
                                horaAgendamento,
                                assunto,
                                oportunidadeId,
                                opportunityIdSalesforce,
                              },
                              index
                            ) => (
                              <Flex gap={4} pb={4} align={"center"} key={index}>
                                <Box
                                  display={"flex"}
                                  w={"fit-content"}
                                  h={"30px"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                >
                                  <IconCalendar width="22" height="22" />
                                </Box>

                                <Box
                                  display={"flex"}
                                  flexDir={"column"}
                                  h={"fit-content"}
                                  alignItems={"center"}
                                  cursor={"pointer"}
                                  // _hover={{
                                  //   textDecoration: "underline"
                                  // }}
                                  onClick={() =>
                                    handleRedirectSchedulle(
                                      oportunidadeId,
                                      opportunityIdSalesforce
                                    )
                                  }
                                >
                                  <Text fontSize="sm" color="gray.600">
                                    {dataAgendamento}, {horaAgendamento}
                                  </Text>
                                  <Text fontSize="xs" color="gray.400">
                                    {assunto}
                                  </Text>
                                </Box>
                              </Flex>
                            )
                          )}
                        </>
                      )}
                    </>
                  )}
                </VStack>
              </GridItem>

              {/* Favoritos */}
              <GridItem
                area="favoritos"
                bg="white"
                p={6}
                borderRadius="lg"
                shadow="md"
                h={"auto"}
              >
                <Flex h={"240px"} align={"center"} justify={"center"}>
                  <Text w={"fit-content"} color={theme.text50}>
                    Novidades em breve 👀
                  </Text>
                </Flex>
                {/* <Heading as="h3" size="md" mb={4}>
                  Favoritos
                </Heading>
                <VStack
                  overflowX={"auto"}
                  h={"200px"}
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#D3D3D3", // Cor do scroll
                      borderRadius: "10px", // Deixa arredondado
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#A0AEC0", // Cor quando o mouse passa
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#F7FAFC", // Fundo do track do scroll
                    },
                  }}
                >
                  {[1, 2, 3, 4, 5].map(() => (
                    <Flex gap={4} pb={4} align={"center"}>
                      <Box
                        display={"flex"}
                        w={"40px"}
                        h={"30px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderWidth={1}
                        borderColor={theme.disable100}
                        borderRadius={"full"}
                      >
                        <IconStar width="16" height="16" />
                      </Box>

                      <Box
                        display={"flex"}
                        flexDir={"column"}
                        h={"fit-content"}
                        alignItems={"center"}
                      >
                        <Text fontSize="sm" color="gray.600">
                          OP-000403450
                        </Text>
                        <Text fontSize="xs" color="gray.400">
                          Paulo Frideman
                        </Text>
                      </Box>
                    </Flex>
                  ))}
                </VStack> */}
              </GridItem>
            </Grid>
          )}
          {/* Main Grid */}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Home;
