import { Box, Text } from "@chakra-ui/react";
import { FaAward } from "react-icons/fa";
import { theme } from "src/styles/theme";

export const useScrollableBoxProps: any = () => {
  return {
    overflowY: "hidden",
    overflow: {
      base: "hidden",
      sm: "hidden",
      md: "initial",
      lg: "initial",
      xl: "initial",
    },
    overflowX: {
      base: "auto",
      sm: "auto",
      md: "initial",
      lg: "initial",
      xl: "initial",
    },
    sx: {
      "::-webkit-scrollbar": {
        height: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "transparent",
        borderRadius: "8px",
      },
      "::-webkit-scrollbar-thumb": {
        background: theme.primary,
        borderRadius: "20px",
      },
    },
  };
};

interface IUseEqualInputSelect {
  id: string;
  name: string;
  checked: boolean;
  inputProps: any;
  checkboxProps: any;
  rootProps: any;
  titleDescription?: string;
  description?: string;
  ranking?: string | null;
}
export const EqualInputSelect = ({
  id,
  checked,
  name,
  checkboxProps,
  inputProps,
  rootProps,
  titleDescription,
  description,
  ranking = null,
}: IUseEqualInputSelect) => {
  const handleRanking = () => {
    if (ranking === "Ouro") return "#E69C08";
    else if (ranking === "Prata") return "#8F8F8F";
    else if (ranking === "Bronze") return "#D6763A";
    else return undefined;
  };

  return (
    <Box as="div" flexDir="column">
      <Box
        as="label"
        htmlFor={id?.toString()}
        display="flex"
        alignItems="center"
        padding={"2px 5px"}
        height={"32px"}
        borderRadius={"8px"}
        transition={"0.3s"}
        cursor="pointer"
        _hover={{
          bg: "#e7edfe",
        }}
        {...rootProps}
      >
        <input {...inputProps} style={{ display: "none" }} />
        <Box
          {...checkboxProps}
          mr="6px"
          w="20px"
          h="20px"
          minW={"20px"}
          maxW={"20px"}
          borderWidth="2px"
          borderColor={theme.disable}
          borderRadius="full"
          _checked={{
            borderColor: theme.primary,
          }}
          pos="relative"
        >
          <Box
            visibility={checked ? "visible" : "hidden"}
            pos="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            w="12px"
            h="12px"
            bg={theme.primary}
            borderRadius="full"
          />
        </Box>
        <Box>
          <Text display={"flex"} fontSize="12px" color={theme.text} w={"fit-content"} gap={"2px"}>
            {name}
            {ranking !== null && (
              <Box
                display={"flex"}
                w={"fit-content"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <FaAward color={handleRanking()} fontSize={"16px"} />
              </Box>
            )}
          </Text>
          {(!!description || !!titleDescription) && (
            <Text fontSize="10px" color={theme.text} w={"fit-content"}>
              {titleDescription ? titleDescription : "Descrição:"} {description}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};
