interface IIcon {
  width: string;
  height: string;
}

export const IconHome = ({ width, height }: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 17.5V6.25L8 0.605751L15.5 6.25V17.5H9.90375V10.8078H6.09625V17.5H0.5Z"
      fill="#FFF"
    />
  </svg>
);
