import React from "react";
import * as Styled from "../../styles";
import { BsBank, BsClockHistory } from "react-icons/bs";
import Tab from "@components/Sales/Tab";
import ActivityComponent from "../ActivityComponent";
import Button from "@components/common/Button";
import Modal from "@components/Sales/Modal";
import CreateScheduleComponent from "../Schedule/CreateScheduleComponent";
import ButtonPopover from "@components/Sales/ButtonPopover";
import DetailComponent from "./DetailComponent";
import CreateTaskCallComponent from "../TasksCalls/CreateTaskCallComponent";
import { OportunityContext } from "@context/oportunityContex";
import CreateCreditRatingComponent from "../CreditRating/CreateCreditRatingComponent";
import { MdOutlineSpatialAudioOff, MdChecklist } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import Stepp from "@components/Sales/Stepp";
import {
  IDetailOportunityComponent,
  IHashMapKeyString,
} from "src/@types/Sales";
import ViewCreditRatingComponent from "../CreditRating/ViewCreditRatingComponent";
import ContractsComponent from "../Contracts/ContractsComponent";
import { RxUpdate } from "react-icons/rx";
import oportunityServiceInstance from "@services/Oportunity/OportunityService";
import QuotesComponent from "../Quotes/QuotesComponent";
import ViewQuotesComponent from "../Quotes/ViewQuotesComponent";
import Spinner from "@components/common/Spinner";
import CreateQuotesComponent from "../Quotes/CreateQuotesComponent";
import RelationshipComponent from "../Relationship/RelationshipComponent";
import CreditRatingComponent from "../CreditRating/CreditRatingComponent";
import { Box, Flex, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import { optionCloseLose } from "@helpers/optionSelect";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { useOnlyMessageToast } from "@hook/useMessageToast";
import { IconMail } from "src/assets/iconMail";
import { IconCall } from "src/assets/iconCall";
import { FaAward } from "react-icons/fa";

const DetailOportunityComponent = ({
  hash = "DetailOportunity",
  setHash,
  navigationCreateAccount,
  index,
  setIndex,
}: IDetailOportunityComponent) => {
  const displayMessageError = useMessageErrorToast();
  const displayMessage = useOnlyMessageToast();
  const {
    faseOp,
    accountIdSalesforce,
    opportunityId,
    opportunityName,
    accessCreateOp,
    setAccessCreateOp,
    contracts,
    setLoading,
    setContracts,
    setOnboardingDocuments,
    accessQuote,
    accessAttachments,
    setDisabledCreateDocument,
    setRegionalOp,
    setFaseOp,
    ranking,
    setRanking,
  } = React.useContext(OportunityContext);
  const [modalSchedule, setModalSchedule] = React.useState(false);
  const [successCreateSchedule, setSuccessCreateSchedule] =
    React.useState(false);
  const [successCreateTaskCall, setSuccessCreateTaskCall] =
    React.useState(false);
  const [modalTaskCall, setModalTaskCall] = React.useState(false);
  const [modalCreditRating, setModalCreditRating] = React.useState(false);
  const [successCreateCreditRating, setSuccessCreateCreditRating] =
    React.useState(false);
  const [updateData, setUpdateData] = React.useState({ data: "" });
  const [edit, setEdit] = React.useState(false);
  const [currentFase, setCurrentFase] = React.useState("");
  const [isEditQuote, setIsEditQuote] = React.useState(false);
  const [canEditQuote, setCanEditQuote] = React.useState(false);
  const [loadingGetOportunity, setloadingGetOportunity] = React.useState(false);
  const [dataOportunidade, setDataOportunidade] = React.useState("");
  const [probabilidade, setProbabilidade] = React.useState("");
  const [modalQuot, setModalQuot] = React.useState(false);
  const [successCreateQuote, setSuccessCreateQuote] = React.useState(false);

  const [showDetailTaskCall, setShowDetailTaskCall] = React.useState(false);
  const [showDetailSchedule, setShowDetailSchedule] = React.useState(false);
  const [cancelButtonAdress, setCancelButtonAdress] = React.useState(false);
  const [showCloseLose, setShowCloseLose] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [loadingCloseLose, setLoadingCloseLose] = React.useState(false);
  const [reasonNumber, setReasonNumber] = React.useState(0);
  const [allowedDrop, setAllowedDrop] = React.useState(false);
  const [emailOp, setEmailOp] = React.useState("");
  const [telephoneOp, setTelephoneOp] = React.useState("");

  let contractsTitle = "Contratos";
  if (contracts.length > 0) {
    contractsTitle += ` (${contracts.length})`;
  }
  const tabLabels = [
    "Oportunidade",
    "Compradores",
    "Tarefas e agendamentos",
    "Avaliações de crédito",
    "Cotações",
    contractsTitle,
  ];
  const tabContents = {
    Oportunidade: (
      <DetailComponent
        setEmailOp={setEmailOp}
        setTelephoneOp={setTelephoneOp}
        indexFirstTab={index}
        edit={edit}
        setEdit={setEdit}
        setCurrentFase={setCurrentFase}
        updateData={updateData}
        setDataOportunidade={setDataOportunidade}
        loadingGetOportunity={loadingGetOportunity}
        setloadingGetOportunity={setloadingGetOportunity}
        setProbabilidade={setProbabilidade}
        cancelButtonAdress={cancelButtonAdress}
        setCancelButtonAdress={setCancelButtonAdress}
        setAllowedDrop={setAllowedDrop}
      />
    ),
    Compradores: (
      <RelationshipComponent
        indexFirstTab={index}
        navigationCreateAccount={navigationCreateAccount}
        updateData={updateData}
      />
    ),
    "Tarefas e agendamentos": (
      <ActivityComponent
        indexFirstTab={index}
        successCreateSchedule={successCreateSchedule}
        navigateCreateSchedule={() => setModalSchedule(true)}
        navigateCreateTask={() => setModalTaskCall(true)}
        successCreateTaskCall={successCreateTaskCall}
        updateData={updateData}
        showDetailTaskCall={showDetailTaskCall}
        setShowDetailTaskCall={setShowDetailTaskCall}
        showDetailSchedule={showDetailSchedule}
        setShowDetailSchedule={setShowDetailSchedule}
      />
    ),
    "Avaliações de crédito": (
      <CreditRatingComponent
        index={index}
        navigateCreateCreditRating={() => setModalCreditRating(true)}
        successCreateCreditRating={successCreateCreditRating}
        setHashCreateCreditRating={setHash}
        updateData={updateData}
      />
    ),
    Cotações: (
      <QuotesComponent
        indexFirstTab={index}
        setHash={setHash}
        setIsEdit={setIsEditQuote}
        navigateCreateQuote={() => setModalQuot(true)}
        setCanEdit={setCanEditQuote}
        successCreateQuote={successCreateQuote}
        setSuccessCreateQuote={setSuccessCreateQuote}
        updateData={updateData}
      />
    ),
    [contractsTitle]: <ContractsComponent />,
  };

  const arrayButtons = [
    {
      title: `Atividade da oportunidade`,
      onClick: () => setModalTaskCall(true),
    },
    {
      title: `Agendamento da oportunidade`,
      onClick: () => setModalSchedule(true),
    },
    {
      title: `Avaliação de crédito`,
      onClick: () => setModalCreditRating(true),
    },
    {
      title: `Cotação da oportunidade`,
      onClick: () => setModalQuot(true),
    },
  ];

  const steps = [
    {
      title: "Aguardando atendimento",
      icon: <BsClockHistory fontSize={"20px"} />,
      states: ["aguardando atendimento corretor"],
    },
    {
      title: "Em atendimento",
      icon: <MdOutlineSpatialAudioOff fontSize={"20px"} />,
      states: ["em atendimento", "visita agendada", "visita realizada"],
    },
    {
      title: "Análise de crédito",
      icon: <BsBank fontSize={"20px"} />,
      states: ["em análise de crédito", "análise de crédito realizada"],
    },
    {
      title: "Cotação",
      icon: <MdChecklist fontSize={"20px"} />,
      states: [
        "proposta",
        "proposta aprovada",
        "proposta reprovada",
        "análise safi",
        "aprovado safi",
        "rejeitado safi",
      ],
    },
    {
      title: "Contrato",
      icon: <IoDocumentTextOutline fontSize={"20px"} />,
      states: [
        "contrato gerado",
        "contrato comunicado",
        "contrato com pendência comercial",
      ],
    },
    {
      title:
        currentFase?.toLowerCase() === "fechado e perdido"
          ? "Fechado e Perdido"
          : "Fechado e Ganho",
      icon: <></>,
      states:
        currentFase?.toLowerCase() === "fechado e perdido"
          ? ["fechado e perdido"]
          : ["fechado e ganho"],
    },
  ];

  // useEffect(() => {
  //   if (accessQuote.nomeDaCotacao === "") {
  //     setIndex(0);
  //   }
  // }, [accessQuote]);

  const handleGetOportunity = async () => {
    setloadingGetOportunity(true);
    try {
      const response = await oportunityServiceInstance.getOportunityById({
        OportunidadeId: accessCreateOp.oportunidadeId,
      });

      if (response.status === 401) {
        setloadingGetOportunity(false);
        return;
      }

      setRanking(response?.ranking);
      setTelephoneOp(response?.telefonePrincipal);
      setEmailOp(response?.emailPrincipal);
      setRegionalOp(response?.regional);
      setDisabledCreateDocument(response?.contaPossuiCPF);
      setCurrentFase(response.fase);
      setFaseOp(
        response?.fase?.toLocaleLowerCase() === "fechado e ganho" ||
          response?.fase?.toLocaleLowerCase() === "fechado e perdido"
      );
      setloadingGetOportunity(false);
    } catch (error) {
      setloadingGetOportunity(false);
      console.log("ErrorHandleGetOportunityById", error);
    }
  };

  const handleUpdateOportunity = async () => {
    setLoadingCloseLose(true);

    try {
      const payload = {
        opportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        OportunidadeId: accessCreateOp.oportunidadeId,
        accountIdSalesforce: accountIdSalesforce,
        motivoQueda: reasonNumber,
      };

      const response =
        await oportunityServiceInstance.updateOportunity(payload);

      if (response.success === false) {
        setLoadingCloseLose(false);
        displayMessageError({ message: response.err });
        setAccessCreateOp({ ...accessCreateOp });
        return;
      }

      displayMessage("Oportunidade editada com sucesso!");
      setAccessCreateOp({ ...accessCreateOp });
      setLoadingCloseLose(false);
      setShowCloseLose(false);
      handleGetOportunity();
      setUpdateData({ data: "any" });
    } catch (error) {
      setLoadingCloseLose(false);
      console.log("ErrorHandleUpdateOportunityById", error);
    }
  };

  const handleRanking = (ranking: string) => {
    if (ranking === "Ouro") return "#E69C08";
    else if (ranking === "Prata") return "#8F8F8F";
    else if (ranking === "Bronze") return "#D6763A";
    else return undefined;
  };

  const SalesHashMap: IHashMapKeyString = {
    DetailOportunity: (
      <>
        <Styled.ContainerTitlePage>
          <Styled.ContainerTitle>
            <Styled.TitlePage>
              {loadingGetOportunity
                ? ""
                : opportunityName ||
                  accessCreateOp?.nomeDaConta?.toUpperCase()}{" "}
              ({opportunityId || accessCreateOp.oportunidadeId}){" "}
            </Styled.TitlePage>
            {loadingGetOportunity ? null : (
              <Flex
                flexDir={{ base: "column", sm: "column", md: "row" }}
                fontSize={"14px"}
                color={theme.text50}
                gap={"14px"}
                mb={{ base: "5px", sm: "5px", md: "0px" }}
              >
                {ranking !== null && (
                  <Box
                    display={"flex"}
                    w={"fit-content"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={"6px"}
                  >
                    <FaAward color={handleRanking(ranking)} fontSize={"18px"} />
                    <Text minW={"fit-content"}> Cliente {ranking}</Text>
                  </Box>
                )}

                <Box
                  display={"flex"}
                  w={"fit-content"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"6px"}
                  cursor="pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(emailOp);
                    displayMessage("Email copiado!");
                  }}
                >
                  <IconMail width="14px" height="14px" />
                  <Text minW={"fit-content"} _hover={{ color: theme.primary }}>
                    {emailOp}
                  </Text>
                </Box>

                <Box
                  display={"flex"}
                  w={"fit-content"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"6px"}
                >
                  <Box w={"fit-content"}>
                    <IconCall width="14px" height="14px" />
                  </Box>
                  <Text minW={"fit-content"} _hover={{ color: theme.primary }}>
                    <a
                      href={`https://web.whatsapp.com/send?phone=${telephoneOp?.replace(/\D/g, "")}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {telephoneOp}
                    </a>
                  </Text>
                </Box>
              </Flex>
            )}
          </Styled.ContainerTitle>

          <Styled.ContainerAnyButtons>
            <Styled.ContainerUpdateButton>
              <Button
                title={
                  <Styled.TitleButtons>
                    <RxUpdate />
                  </Styled.TitleButtons>
                }
                disabled={faseOp}
                onClick={() => {
                  setUpdateData({ data: "any" });
                  handleGetOportunity();
                  setLoading(true);
                  setOnboardingDocuments([]);
                  oportunityServiceInstance
                    .getOnboardingDocuments({
                      CodigoRelacionamentoComComprador:
                        accessAttachments.codigoRelacionamentoComComprador,
                      CPF: accessAttachments.cpf,
                    })
                    .then((d) => {
                      setOnboardingDocuments(d);
                      setLoading(false);
                    });

                  oportunityServiceInstance
                    .getContracts({
                      opportunityIdSalesforce:
                        accessCreateOp.oportunityIdSalesforce,
                    })
                    .then((c) => {
                      setContracts(c);
                      setLoading(false);
                    });
                }}
              />
            </Styled.ContainerUpdateButton>
            <Styled.ContainerButtonPlus indexTab={index === 0}>
              {index === 0 && (
                <Button
                  title={"Editar"}
                  onClick={() => {
                    setEdit(true);
                    setCancelButtonAdress(false);
                  }}
                  disabled={edit || faseOp}
                />
              )}

              <ButtonPopover
                disabled={faseOp}
                title={"Criar"}
                popoverContent={arrayButtons.map((button) => (
                  <Styled.AreaButtonsPopover key={button.title}>
                    <Styled.BoxButtonsPopover>
                      <Styled.TitleButtonsPopover onClick={button.onClick}>
                        {button.title}
                      </Styled.TitleButtonsPopover>

                      <Styled.DividerButtonsPopover />
                    </Styled.BoxButtonsPopover>
                  </Styled.AreaButtonsPopover>
                ))}
              />
            </Styled.ContainerButtonPlus>

            {allowedDrop && (
              <Flex>
                <Button
                  title={"Fechar e perder"}
                  onClick={() => {
                    setShowCloseLose(true);
                  }}
                  error
                />
              </Flex>
            )}
          </Styled.ContainerAnyButtons>
        </Styled.ContainerTitlePage>

        <Styled.ContainerFaseDatailOportunity>
          {loadingGetOportunity ? (
            <Styled.AreaFaseDatailOportunityLoading>
              <Spinner />
            </Styled.AreaFaseDatailOportunityLoading>
          ) : (
            <Styled.AreaFaseDatailOportunity>
              <span>Acompanhamento da oportunidade:</span>

              <Styled.ContainerFollow>
                <span>{dataOportunidade}</span>
                <Styled.DividerFollow />
              </Styled.ContainerFollow>

              <Styled.ContainerStepp>
                <Stepp
                  steps={steps}
                  activeStep={currentFase}
                  progressPercent={probabilidade ? Number(probabilidade) : 0}
                />
              </Styled.ContainerStepp>
            </Styled.AreaFaseDatailOportunity>
          )}
        </Styled.ContainerFaseDatailOportunity>

        <Styled.ContainerActivityDatailOportunity>
          <Tab
            widthXl="100%"
            width="100%"
            widthMd="100%"
            widthSm="100%"
            widthBase="100%"
            index={index}
            setIndex={setIndex}
            tabLabels={tabLabels}
            tabContents={tabContents}
            disabled={loadingGetOportunity}
          />
        </Styled.ContainerActivityDatailOportunity>
      </>
    ),

    ViewCreditRatingComponent: <ViewCreditRatingComponent hash={hash} />,

    ViewQuotesComponent: (
      <ViewQuotesComponent
        hash={hash}
        isEdit={isEditQuote}
        setIsEdit={setIsEditQuote}
        canEdit={canEditQuote}
      />
    ),
  };

  return (
    <>
      <Modal
        isCloseLose
        subtitleImportant
        onOpen={showCloseLose}
        title={`Deseja fechar e perder a ${accessCreateOp.oportunidadeId}?`}
        subtitle="*Ao clicar em Fechar e perder, você deve estar ciente que este processo é irreversível."
        onClose={() => {
          setShowCloseLose(false);
          setReason("");
        }}
        minH="250px"
      >
        <Flex flexDir={"column"} justify={"space-between"} minH={"160px"}>
          <Flex w={"80%"}>
            <InputSelect
              isRequirement
              widht="70%"
              height="44%"
              title="Motivo (obrigatório)"
              label={reason || "Selecione uma opção"}
              isSelected={reason}
              options={optionCloseLose}
              selectedOption={reason}
              onChange={(e) => {
                setReason(e.currentTarget.value);
                setReasonNumber(Number(e.currentTarget.id) + 1);
              }}
            />
          </Flex>

          <Flex
            p={"5px"}
            justify={"space-between"}
            align={"center"}
            gap={"10px"}
            w={"80%"}
          >
            <Button
              title="Cancelar"
              onClick={() => {
                setShowCloseLose(false);
                setReason("");
              }}
            />

            <Button
              title={loadingCloseLose ? <Spinner /> : "Fechar e perder"}
              onClick={handleUpdateOportunity}
              disabled={!reason || loadingCloseLose}
              colorError
            />
          </Flex>
        </Flex>
      </Modal>

      <Modal
        onOpen={modalSchedule}
        title="Criar agendamento"
        subtitle="Preencha as informações para registro e acompanhamento no sistema."
        onClose={() => setModalSchedule(false)}
      >
        <CreateScheduleComponent
          onBack={() => setModalSchedule(false)}
          setSuccessCreateSchedule={setSuccessCreateSchedule}
          setShowDetailSchedule={setShowDetailSchedule}
        />
      </Modal>

      <Modal
        onOpen={modalTaskCall}
        title="Criar atividade da oportunidade"
        subtitle="Preencha as informações para registro e acompanhamento no sistema."
        onClose={() => setModalTaskCall(false)}
      >
        <CreateTaskCallComponent
          onBack={() => setModalTaskCall(false)}
          setSuccessCreateTaskCall={setSuccessCreateTaskCall}
          setShowDetailTaskCall={setShowDetailTaskCall}
        />
      </Modal>

      <Modal
        onOpen={modalCreditRating}
        title="Criar avaliação de crédito"
        subtitle="Preencha as informações que serão enviadas para o correspondente bancário realizar a análise de crédito."
        onClose={() => setModalCreditRating(false)}
      >
        <CreateCreditRatingComponent
          onBack={() => setModalCreditRating(false)}
          setSuccessCreateCreditRating={setSuccessCreateCreditRating}
          setHash={setHash}
        />
      </Modal>

      <Modal
        onOpen={modalQuot}
        title="Criar cotação"
        subtitle="Preencha as informações para registro e acompanhamento no sistema."
        onClose={() => setModalQuot(false)}
      >
        <CreateQuotesComponent
          onBack={() => setModalQuot(false)}
          setSuccessCreateQuote={setSuccessCreateQuote}
          setIsEdit={setIsEditQuote}
          setHash={setHash}
        />
      </Modal>

      {SalesHashMap[hash]}
    </>
  );
};

export default DetailOportunityComponent;
