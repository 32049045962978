interface IIcon {
  width: string;
  height: string;
  color?: string;
}

export const IconCall = ({ width, height, color = "#59667A" }: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9307 16C13.2449 16 11.5167 15.5779 9.74625 14.7337C7.97575 13.8894 6.33083 12.7044 4.8115 11.1788C3.29233 9.65325 2.11058 8.00842 1.26625 6.24425C0.422083 4.48008 0 2.75508 0 1.06925C0 0.76375 0.1 0.509166 0.3 0.305499C0.5 0.101833 0.75 0 1.05 0H3.523C3.79483 0 4.032 0.0855838 4.2345 0.25675C4.43717 0.427917 4.57442 0.6475 4.64625 0.9155L5.14225 3.3C5.18842 3.5795 5.18008 3.82375 5.11725 4.03275C5.05442 4.24175 4.9435 4.41292 4.7845 4.54625L2.5905 6.59225C3.00067 7.33708 3.45417 8.02908 3.951 8.66825C4.44783 9.30742 4.97767 9.91283 5.5405 10.4845C6.12117 11.0653 6.74617 11.6054 7.4155 12.1048C8.08467 12.6041 8.81925 13.0756 9.61925 13.5193L11.7578 11.3422C11.9206 11.1666 12.1036 11.0509 12.3068 10.9952C12.5099 10.9394 12.7346 10.9282 12.9808 10.9615L15.0845 11.3923C15.3563 11.4589 15.5769 11.5958 15.7462 11.803C15.9154 12.01 16 12.2475 16 12.5155V14.95C16 15.25 15.8982 15.5 15.6945 15.7C15.4908 15.9 15.2362 16 14.9307 16ZM2.12125 5.65375L4.0405 3.8885C4.1045 3.83717 4.14617 3.76667 4.1655 3.677C4.18467 3.58717 4.18142 3.50383 4.15575 3.427L3.7115 1.30775C3.68583 1.20508 3.641 1.12817 3.577 1.077C3.51283 1.02567 3.4295 1 3.327 1H1.275C1.198 1 1.13392 1.02567 1.08275 1.077C1.03142 1.12817 1.00575 1.19225 1.00575 1.26925C1.02492 1.95258 1.132 2.666 1.327 3.4095C1.52183 4.15317 1.78658 4.90125 2.12125 5.65375ZM10.5712 13.9885C11.2467 14.3232 11.9669 14.5706 12.7318 14.7308C13.4964 14.8911 14.1628 14.977 14.7308 14.9885C14.8077 14.9885 14.8718 14.9628 14.923 14.9115C14.9743 14.8602 15 14.7961 15 14.7193V12.7115C15 12.609 14.9743 12.5257 14.923 12.4615C14.8718 12.3975 14.7949 12.3527 14.6923 12.327L12.8422 11.948C12.7654 11.9223 12.6982 11.9192 12.6405 11.9385C12.5827 11.9577 12.5218 11.9993 12.4578 12.0635L10.5712 13.9885Z"
      fill={color}
    />
  </svg>
);
