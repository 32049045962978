import React from "react";
import * as Styled from "../InputSelect/styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useClickOutside } from "@hook/useClickOutside";
import Spinner from "@components/common/Spinner";
import { InputRadioButton } from "../InputSelect";

interface Option {
  id?: any;
  name?: any;
  idPDV?: any;
  nome?: any;
  configuracaoParcelaId?: any;
  nomeSerie?: any;
  nomeCorrespondente?: any;
  condicaoDePagamentoMatrizId?: any;
  cnpj?: any;
  descricao?: any;
  ranking?: any;
}

interface ISelect {
  title: string;
  label: string;
  error?: boolean;
  isSelected?: string;
  height?: string;
  widht?: string;
  disabled?: boolean;
  isRequirement?: boolean;
  isOportunity?: boolean;
  loading?: boolean;
  onClick?: any;
  description?: Option[];
  
  options: Option[];
  selectedOption: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  setId: React.Dispatch<React.SetStateAction<string>>;
}

const InputSelectWithService = ({
  title,
  label,
  error,
  isSelected,
  height,
  widht,
  disabled,
  isRequirement,
  options,
  selectedOption,
  onChange,
  onClick,
  isOportunity,
  loading,
  setId,
  description,
}: ISelect) => {
  const wrapperRef: any = React.useRef(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [check, setCheck] = React.useState("");

  useClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  const determineId = (option: Option) =>
    option.id ||
    option.idPDV ||
    option.configuracaoParcelaId ||
    option.condicaoDePagamentoMatrizId;

  const determineName = (option: Option) =>
    option.name || option.nome || option.nomeSerie || option.nomeCorrespondente;

  const determineDescription = (option: Option) => option.condicaoDePagamentoMatrizId ? option.condicaoDePagamentoMatrizId : option.descricao;

  const isChecked = (option: Option, index: number) => {
    const combinedOptionValue = `${determineName(
      option
    )} - ${determineDescription(option)}`;

    return combinedOptionValue === `${selectedOption} - ${check}`;
  };

  React.useEffect(() => {
    if (isSelected) {
      setOpen(false);
    }
  }, [isSelected]);

  return (
    <Styled.Container ref={wrapperRef}>
      <Styled.Label>
        {isRequirement && <Styled.Requirement>*</Styled.Requirement>}
        {title}
      </Styled.Label>
      <Styled.SelectButton
        isOportunity={isOportunity}
        disabled={disabled}
        error={error}
        onClick={() => {
          onClick();
          setOpen(!open);
        }}
      >
        <Styled.Title isSize={label.length > 20} isSelected={isSelected}>
          {label}
        </Styled.Title>
        <Styled.ContainerIcon>
          <IoIosArrowUp />
          <IoIosArrowDown />
        </Styled.ContainerIcon>
      </Styled.SelectButton>

      {open && (
        <Styled.ListItems
          widht={widht}
          height={height}
          isOpen={disabled ? false : open}
        >
          {loading ? (
            <Spinner />
          ) : (
            options.map((option, index) => (
              <InputRadioButton
                key={determineId(option)}
                id={determineId(option)}
                name={determineName(option)}
                checked={isChecked(option, index)}
                onChange={(e) => {
                  setOpen(false);
                  setCheck(option.condicaoDePagamentoMatrizId ? option.condicaoDePagamentoMatrizId : determineDescription(option));
                  setId(determineId(option));

                  onChange(e);
                }}
                description={option.condicaoDePagamentoMatrizId ? option.descricao : determineDescription(option)}
                ranking={option.ranking}
              />
            ))
          )}
        </Styled.ListItems>
      )}
    </Styled.Container>
  );
};
export default InputSelectWithService;
