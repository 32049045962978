import { Box, Flex, Text } from "@chakra-ui/react";
import Spinner from "@components/common/Spinner";
import { LoginContext } from "@context/loginContext";
import { OportunityContext } from "@context/oportunityContex";
import { UsuallyContext } from "@context/usuallyContex";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { IDashboardOportunidade } from "src/@types/Home";
import { theme } from "src/styles/theme";

type Props = {
  data: IDashboardOportunidade[];
};

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];

const CustomOportunityByStatus = ({ data }: Props) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const { setPageSidebar } = React.useContext(LoginContext);
  const { setHashSalesSidebar } = React.useContext(UsuallyContext);
  const { setFaseMyOportunity } = React.useContext(OportunityContext);

  const handleFase = (fase: string) => {
    if (fase === "Análise de Crédito") return "Em Análise de Crédito";
    else return fase
  };

  const handleRedirect = (fase: string) => {
    navigate("/oportunidades");
    setPageSidebar("Minhas oportunidades");
    localStorage.setItem("@option", "Minhas oportunidades");
    setHashSalesSidebar("MyOportunityComponent");
    setFaseMyOportunity(handleFase(fase));
  };

  const onMouseEnter = (dataIndex: number) => {
    setActiveIndex(dataIndex);
  };

  const onMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <Flex align="center" justify="space-between" mt={10}>
      {/* PieChart Responsivo */}
      {data.length === 0 ? (
        <Flex align="center" justify="center" h={"190px"}>
          <Text w={"fit-content"} color={theme.text50}>
            Não há oportunidades por status!
          </Text>
        </Flex>
      ) : (
        <>
          <Box position="relative" w="40%" h="190px">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  dataKey="quantidade"
                  nameKey="fase"
                  cx="50%"
                  cy="50%"
                  innerRadius={80}
                  outerRadius={90}
                  paddingAngle={0}
                  onMouseEnter={(_, index) => onMouseEnter(index)}
                  onMouseLeave={onMouseLeave}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      stroke={activeIndex === index ? theme.primary : "none"}
                      strokeWidth={activeIndex === index ? 2 : 1}
                    />
                  ))}
                </Pie>
                <Tooltip
                  wrapperStyle={{ zIndex: 10 }}
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      const { fase, quantidade } = payload[0].payload;
                      return (
                        <Box
                          p={2}
                          bg="white"
                          boxShadow="md"
                          borderRadius="md"
                          border="1px solid #ddd"
                          zIndex={2}
                        >
                          <Text fontWeight="bold">{fase}</Text>
                          <Text>{`Quantidade: ${quantidade}`}</Text>
                        </Box>
                      );
                    }
                    return null;
                  }}
                />
              </PieChart>
            </ResponsiveContainer>

            {/* Número centralizado */}
            <Box
              display={"flex"}
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontSize="2rem"
              fontWeight="semibold"
              color={theme.text}
              w={"fit-content"}
              zIndex={1}
            >
              {data.reduce((acc, curr) => acc + curr.quantidade, 0)}
            </Box>
          </Box>

          {/* Legenda */}
          <Box
            display={"flex"}
            flexDir={"column"}
            w="70%"
            h={"150px"}
            flexWrap={"wrap"}
            gap={"6px"}
            overflowX={"hidden"}
            css={{
              "&::-webkit-scrollbar": {
                width: "6px",
                height: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#D3D3D3", // Cor do scroll
                borderRadius: "10px", // Deixa arredondado
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#A0AEC0", // Cor quando o mouse passa
              },
              "&::-webkit-scrollbar-track": {
                background: "#F7FAFC", // Fundo do track do scroll
              },
            }}
          >
            {data.map(({ fase, quantidade }, index) => (
              <Flex key={index} align="center" mb={2} w={"50%"}>
                <Box
                  w={3}
                  h={3}
                  borderRadius="full"
                  bg={COLORS[index % COLORS.length]}
                  mr={3}
                ></Box>
                <Text
                  fontSize="sm"
                  w={"fit-content"}
                  color={theme.text}
                  cursor={"pointer"}
                  _hover={{
                    textDecoration: "underline",
                  }}
                  onClick={() => handleRedirect(fase)}
                >
                  {fase}
                </Text>
              </Flex>
            ))}
          </Box>
        </>
      )}
    </Flex>
  );
};

export default CustomOportunityByStatus;
