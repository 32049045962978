import React from "react";
import { useFormik } from "formik";
import { useCustomFormikEdit } from "@hook/useCustomFormikEdit";
import { LoginContext } from "@context/loginContext";
import myProfileService from "@services/MyProfile/myProfileService";
import { removeAccent, documentMask } from "@helpers/format";
import Notify from "@components/MyProfile/Notify";
import Spinner from "@components/common/Spinner";
import * as Styled from "./styles";
import { UsuallyContext } from "@context/usuallyContex";
import BreadCrumb from "@components/common/BreadCrumb";
import { useCustomFormikEmpty } from "@hook/useCustomFormikEmpty";
import Alert from "@components/common/Alert";
import Button from "@components/common/Button";
import Modal from "@components/common/Modal";
import InputPassword from "@components/Login/Inputs/InputPassword";
import { regexNumber, regexRepeat, seqPassword } from "@helpers/securePassword";
import { useNavigate } from "react-router-dom";
import ModalUpdatePasswordRequirements from "./components/ModalUpdatePasswordRequirements";
import {
  SectionCRECITTI,
  SectionDadosFamiliares,
  SectionDadosFinanceiros,
  SectionDadosParaContato,
  SectionDadosPessoais,
  SectionEnderecos,
  SectionInformacoeesDeContato,
  SectionTipoDeComissionamento,
} from "./components/SectionInputsComponent";

const MyProfile: React.FC = () => {
  const { openSidebar, signOut, setPageSidebar } =
    React.useContext(LoginContext);
  const {
    setLoadingUpdateProfile,
    setIsSuccess,
    setDataMyProfile,
    initialValuesMyProfile: initialValues,
    setEmptyInput,
  } = React.useContext(UsuallyContext);
  const navigate = useNavigate();
  const [selectTshirt, setSelectTshirt] = React.useState("");
  const [selectSchooling, setSelectSchooling] = React.useState("");
  const [selectCivilStatus, setSelectCivilStatus] = React.useState("");
  const [selectNacionality, setSelectNacionality] = React.useState("");
  const [selectNaturalness, setSelectNaturalness] = React.useState("");
  const [selectUfRg, setSelectUfRg] = React.useState("");
  const [selectBank, setSelectBank] = React.useState("");
  const [selectTypeAccount, setSelectTypeAccount] = React.useState("");
  const [selectTipoCorretor, setSelectTipoCorretor] = React.useState("");
  const [selectState, setSelectState] = React.useState("");
  const [selectComissionBilling, setSelectComissionBilling] =
    React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [loadingUpdatePassword, setLoadingUpdatePassword] =
    React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [isEditSelect, setIsEditSelect] = React.useState(false);
  const [isEditAddress, setIsEditAddress] = React.useState(false);
  const [updatePassword, setUpdatePassword] = React.useState(false);
  const [openUpdatePassword, setOpenUpdatePassword] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
  const [id, setId] = React.useState(0);
  const [errorRadio, setErrorRadio] = React.useState<boolean>(false);
  const [errorUpdatePassword, setErrorUpdatePassword] =
    React.useState<boolean>(false);
  const [validateInput, setValidateInput] = React.useState({
    number: false,
    length: false,
    repeat: false,
    document: false,
    sequecial: false,
    date: false,
    confirmPassword: false,
  });
  const [faturamentoComissao, setFaturamentoComissao] = React.useState("");

  function titleButton() {
    if (loadingUpdatePassword) return <Spinner />;
    if (errorUpdatePassword) return "ACESSO INVÁLIDO";
    return "CONFIRMAR";
  }

  const securePassword = (password: string, confirmPassword: string) => {
    const cpfUser: any = localStorage.getItem("@cpf");
    const dateUser: any = localStorage.getItem("@dtNasc");

    const length = !password || password.length >= 6;
    const DocumentEqualPassword =
      documentMask(cpfUser) !== documentMask(password);
    const dateEqueal = dateUser?.replace(/\//g, "") !== password;
    const passwordEqual = password === confirmPassword;

    setValidateInput({
      number: !password || regexNumber.test(password),
      length,
      repeat: regexRepeat.test(password),
      document: DocumentEqualPassword,
      sequecial: seqPassword(password),
      date: dateEqueal,
      confirmPassword: passwordEqual,
    });
  };

  const handleUpdatePassword = async () => {
    setLoadingUpdatePassword(true);
    const email: any = localStorage.getItem("@email");
    try {
      const response = await myProfileService.updatePassord({
        email,
        SenhaAntiga: currentPassword,
        SenhaAtual: newPassword,
      });

      if (response.status >= 400) {
        setLoadingUpdatePassword(false);
        setErrorUpdatePassword(true);
        console.log("error4000", response);
        return;
      }

      setOpenUpdatePassword(false);
      sessionStorage.removeItem("@token");
      navigate("/successUpdatePassword", {
        state: { email, password: newPassword },
      });
      setErrorUpdatePassword(false);
      setLoadingUpdatePassword(false);
    } catch (error: any) {
      setLoadingUpdatePassword(false);
      console.log("errorhandleCreatePassword", error);
    }
  };

  const breadcrumbItems = [
    {
      name: "Principal",
      link: "/",
      onClick: () => {
        setPageSidebar("Principal");
        localStorage.setItem("@option", "Principal");
      },
    },
    { name: "Configurações", link: "#" },
    { name: "Meu perfil" },
  ];

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (value) => {
      setLoadingUpdateProfile(true);

      try {
        const response = await myProfileService.updateProfile({
          usuarioId: id,
          dadosPessoais: {
            escolaridade: selectSchooling,
            tamanhoCamiseta: selectTshirt,
            dataAniversario: value?.dateBt,
            estadoCivil: selectCivilStatus,
            nacionalidade: selectNacionality,
            naturalidade: value?.naturalness,
            pis: value?.pis,
            ufrg: selectUfRg,
            ufNaturalidade: selectNaturalness,
          },
          dadosContato: {
            telefone: value?.telephone,
            celular1: value?.cell,
            celular2: value?.cell2,
            outroTelefone: value?.otherTell,
          },
          dadosFamilia: {
            nomePai: value?.fatherName,
            nomeConjuge: value?.spouse,
            nomeMae: value?.motherName,
            quantidadeFilhos: value?.quantitySoon ? value?.quantitySoon : 0,
          },
          dadosFinanceiros: {
            banco: selectBank,
            agencia: value?.agency,
            tipoConta: selectTypeAccount,
            conta: value?.account,
          },
          dadosTipoComissionamento: {
            tipoCorretor: selectTipoCorretor,
            faturamentoComissao: removeAccent(selectComissionBilling),
            cnpj: value?.cnpj,
          },
        });

        if (response?.status >= 400 && response?.status !== 401) {
          setLoadingUpdateProfile(false);
          setError(true);
          return;
        }
        if (response?.status === 401) {
          setLoadingUpdateProfile(false);
          setErrorStatus(true);
          return;
        }

        setError(false);
        setErrorStatus(false);
        setLoadingUpdateProfile(false);
        setIsSuccess(true);
      } catch (error: any) {
        setLoadingUpdateProfile(false);
        console.log("errorUpdateProfile", error);
      }
    },
  });

  const { isEdited } = useCustomFormikEdit(formik, initialValues);
  const { empty } = useCustomFormikEmpty({
    formik,
    tShirt: selectTshirt,
    civilStatus: selectCivilStatus,
    nacionality: selectNacionality,
    UfNaturalness: selectNaturalness,
    UfRg: selectUfRg,
    bank: selectBank,
    TipoCorretor: selectTipoCorretor,
    CommissionBilling: selectComissionBilling,
  });

  const handleGetProfile = async () => {
    setLoading(true);
    try {
      const response = await myProfileService.getProfile();

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        setError(true);
        return;
      }

      if (response?.status === 401) {
        setLoading(false);
        setErrorStatus(true);
        return;
      }

      setFaturamentoComissao(response?.faturamentoComissao);
      setErrorStatus(false);
      setLoading(false);
      setError(false);
      setDataMyProfile(response);
      setId(response?.usuarioId);
      setIsEditAddress(response?.podeEditarEndereco);

      setSelectTshirt(
        response?.tamanhoCamiseta ? response?.tamanhoCamiseta : ""
      );
      setSelectSchooling(response?.escolaridade ? response?.escolaridade : "");
      setSelectCivilStatus(response?.estadoCivil ? response?.estadoCivil : "");
      setSelectNacionality(
        response?.nacionalidade ? response?.nacionalidade : ""
      );
      setSelectNaturalness(
        response?.ufNaturalidade ? response?.ufNaturalidade : ""
      );
      setSelectUfRg(response?.ufrg ? response?.ufrg : "");
      setSelectBank(response?.banco ? response?.banco : "");
      setSelectTypeAccount(response?.tipoConta ? response?.tipoConta : "");
      setSelectTipoCorretor(
        response?.tipoCorretor ? response?.tipoCorretor : ""
      );
      setSelectState(response?.uf ? response?.uf : "");
      setSelectComissionBilling(
        response?.faturamentoComissao ? response?.faturamentoComissao : ""
      );
    } catch (error: any) {
      setLoading(false);
      console.log("errorHandleGetProfile", error);
    }
  };

  React.useEffect(() => {
    handleGetProfile();
  }, []);

  React.useEffect(() => {
    securePassword(newPassword, confirmNewPassword);
  }, [confirmNewPassword, newPassword]);

  React.useEffect(() => {
    const isError =
      !newPassword ||
      !validateInput.number ||
      !validateInput.length ||
      validateInput.sequecial ||
      !validateInput.document ||
      validateInput.repeat ||
      !validateInput.confirmPassword ||
      !validateInput.date;

    setErrorRadio(isError);
  }, [newPassword, validateInput]);

  React.useEffect(() => {
    setEmptyInput(empty);
  }, [empty, setEmptyInput]);

  return (
    <Styled.Area open={openSidebar}>
      <Styled.Container>
        <Alert
          onOpen={error || errorStatus}
          title="Erro ao carregar os dados!"
          titleButton={errorStatus ? "Logar novamente" : "Voltar"}
          description={
            errorStatus
              ? "Sua sessão expirou, faça login novamante para ter acesso!"
              : "Não foi possível obter os dados do seu perfil."
          }
          onClick={() => {
            if (errorStatus) {
              signOut();
            } else {
              handleGetProfile();
            }
          }}
        />
        <Styled.SubContainer>
          <Styled.ContainerTop>
            <BreadCrumb items={breadcrumbItems} />
          </Styled.ContainerTop>

          <Notify
            onOpen={isEdited || isEditSelect}
            onClickCancel={() => {
              window.location.reload();
            }}
            onClickSave={formik.handleSubmit}
          />
          <Notify
            isUpdatePassword
            onOpen={updatePassword}
            onClickCancel={() => setUpdatePassword(false)}
            onClickSave={() => {
              setOpenUpdatePassword(true);
              setUpdatePassword(false);
            }}
          />

          <Modal
            title="Alterar senha"
            onOpen={openUpdatePassword}
            onClose={() => {
              setOpenUpdatePassword(false);
              setErrorUpdatePassword(false);
              setCurrentPassword("");
              setNewPassword("");
              setConfirmNewPassword("");
            }}
          >
            <Styled.ContainerUpdatePassword>
              <Styled.ContainerUpdatePasswordLeft>
                <InputPassword
                  placeholder="Senha Atual"
                  value={currentPassword}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                    setErrorUpdatePassword(false);
                  }}
                  message={"Campo obrigatório"}
                  error={errorUpdatePassword}
                />
                <Styled.Divider />
                <InputPassword
                  placeholder="Nova Senha"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setErrorUpdatePassword(false);
                  }}
                  message={"Campo obrigatório"}
                  error={errorUpdatePassword}
                />
                <InputPassword
                  placeholder="Confirme sua senha"
                  value={confirmNewPassword}
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                    setErrorUpdatePassword(false);
                  }}
                  message={"Campo obrigatório"}
                  error={errorUpdatePassword}
                />

                <Styled.ContainerButtonLeft>
                  <Button
                    title={titleButton()}
                    onClick={handleUpdatePassword}
                    disabled={
                      loadingUpdatePassword ||
                      !!errorRadio ||
                      !currentPassword ||
                      !newPassword
                    }
                    error={errorUpdatePassword}
                  />
                  <Styled.ButtonNeedHelpLeft>
                    Precisa de ajuda?
                  </Styled.ButtonNeedHelpLeft>
                </Styled.ContainerButtonLeft>
              </Styled.ContainerUpdatePasswordLeft>

              <Styled.DividerVertical />

              <Styled.ContainerUpdatePasswordRight>
                <ModalUpdatePasswordRequirements
                  validateInput={validateInput}
                />
              </Styled.ContainerUpdatePasswordRight>
            </Styled.ContainerUpdatePassword>
          </Modal>

          <Styled.ContainerTitlePage>
            <Styled.TitlePage>Meu Perfil</Styled.TitlePage>
            <Styled.ContainerButtonTilte>
              <Button
                title={
                  <>
                    <Styled.IconHiOutlineLockClosed />
                    ALTERAR MINHA SENHA
                  </>
                }
                error
                onClick={() => setUpdatePassword(true)}
              />
            </Styled.ContainerButtonTilte>
          </Styled.ContainerTitlePage>

          <Styled.Form>
            <Styled.ContainerSection>
              <Styled.ContainerTitle>
                <Styled.Title>Informações de contato</Styled.Title>
              </Styled.ContainerTitle>
              {loading ? (
                <Styled.ContainerSpinner>
                  <Spinner />
                </Styled.ContainerSpinner>
              ) : (
                <SectionInformacoeesDeContato formik={formik} />
              )}
            </Styled.ContainerSection>

            <Styled.ContainerSection>
              <Styled.ContainerTitle>
                <Styled.Title>Dados pessoais</Styled.Title>
              </Styled.ContainerTitle>
              {loading ? (
                <Styled.ContainerSpinner>
                  <Spinner />
                </Styled.ContainerSpinner>
              ) : (
                <SectionDadosPessoais
                  formik={formik}
                  selectCivilStatus={selectCivilStatus}
                  setSelectCivilStatus={setSelectCivilStatus}
                  selectNacionality={selectNacionality}
                  setSelectNacionality={setSelectNacionality}
                  selectNaturalness={selectNaturalness}
                  setSelectNaturalness={setSelectNaturalness}
                  selectSchooling={selectSchooling}
                  setSelectSchooling={setSelectSchooling}
                  selectTShirt={selectTshirt}
                  setSelectTshirt={setSelectTshirt}
                  selectUfRg={selectUfRg}
                  setSelectUfRg={setSelectUfRg}
                  setIsEditSelect={setIsEditSelect}
                />
              )}
            </Styled.ContainerSection>

            <Styled.ContainerSection>
              <Styled.ContainerTitle>
                <Styled.Title>Dados para contato</Styled.Title>
              </Styled.ContainerTitle>

              {loading ? (
                <Styled.ContainerSpinner>
                  <Spinner />
                </Styled.ContainerSpinner>
              ) : (
                <SectionDadosParaContato formik={formik} />
              )}
            </Styled.ContainerSection>

            <Styled.ContainerSection>
              <Styled.ContainerTitle>
                <Styled.Title>Dados familiares</Styled.Title>
              </Styled.ContainerTitle>

              {loading ? (
                <Styled.ContainerSpinner>
                  <Spinner />
                </Styled.ContainerSpinner>
              ) : (
                <SectionDadosFamiliares
                  formik={formik}
                  selectCivilStatus={selectCivilStatus}
                />
              )}
            </Styled.ContainerSection>

            <Styled.ContainerSection>
              <Styled.ContainerTitle>
                <Styled.Title>CRECI/TTI</Styled.Title>
              </Styled.ContainerTitle>
              {loading ? (
                <Styled.ContainerSpinner>
                  <Spinner />
                </Styled.ContainerSpinner>
              ) : (
                <SectionCRECITTI formik={formik} />
              )}
            </Styled.ContainerSection>

            <Styled.ContainerSection>
              <Styled.ContainerTitle>
                <Styled.Title>Dados Financeiros</Styled.Title>
              </Styled.ContainerTitle>
              {loading ? (
                <Styled.ContainerSpinner>
                  <Spinner />
                </Styled.ContainerSpinner>
              ) : (
                <SectionDadosFinanceiros
                  formik={formik}
                  selectBank={selectBank}
                  setSelectBank={setSelectBank}
                  selectTypeAccount={selectTypeAccount}
                  setSelectTypeAccount={setSelectTypeAccount}
                  setIsEditSelect={setIsEditSelect}
                  faturamentoComissao={faturamentoComissao}
                />
              )}
            </Styled.ContainerSection>

            <Styled.ContainerSection>
              <Styled.ContainerTitle>
                <Styled.Title>Tipo de comissionamento</Styled.Title>
              </Styled.ContainerTitle>
              {loading ? (
                <Styled.ContainerSpinner>
                  <Spinner />
                </Styled.ContainerSpinner>
              ) : (
                <SectionTipoDeComissionamento formik={formik} />
              )}
            </Styled.ContainerSection>

            {isEditAddress && (
              <Styled.ContainerSection>
                <Styled.ContainerTitle>
                  <Styled.Title>Endereço</Styled.Title>
                </Styled.ContainerTitle>
                {loading ? (
                  <Styled.ContainerSpinner>
                    <Spinner />
                  </Styled.ContainerSpinner>
                ) : (
                  <SectionEnderecos formik={formik} />
                )}
              </Styled.ContainerSection>
            )}
          </Styled.Form>
        </Styled.SubContainer>
      </Styled.Container>
    </Styled.Area>
  );
};

export default MyProfile;
