interface IIcon {
  width: string;
  height: string;
}

export const IconCalendar = ({ width, height }: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.6155 19C1.15517 19 0.770833 18.8458 0.4625 18.5375C0.154167 18.2292 0 17.8448 0 17.3845V4.61551C0 4.15517 0.154167 3.77084 0.4625 3.46251C0.770833 3.15417 1.15517 3.00001 1.6155 3.00001H3.3845V0.769257H4.4615V3.00001H11.6155V0.769257H12.6155V3.00001H14.3845C14.8448 3.00001 15.2292 3.15417 15.5375 3.46251C15.8458 3.77084 16 4.15517 16 4.61551V17.3845C16 17.8448 15.8458 18.2292 15.5375 18.5375C15.2292 18.8458 14.8448 19 14.3845 19H1.6155ZM1.6155 18H14.3845C14.5385 18 14.6796 17.9359 14.8077 17.8078C14.9359 17.6796 15 17.5385 15 17.3845V8.61551H1V17.3845C1 17.5385 1.06408 17.6796 1.19225 17.8078C1.32042 17.9359 1.4615 18 1.6155 18ZM1 7.61526H15V4.61551C15 4.46151 14.9359 4.32042 14.8077 4.19226C14.6796 4.06409 14.5385 4.00001 14.3845 4.00001H1.6155C1.4615 4.00001 1.32042 4.06409 1.19225 4.19226C1.06408 4.32042 1 4.46151 1 4.61551V7.61526Z"
      fill="#E37278"
    />
  </svg>
);
