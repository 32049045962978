import React from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
} from "@chakra-ui/react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Comissao } from "src/@types/Home";
import { formatMoney } from "@helpers/format";

interface ICustomComitions {
  showValues: boolean;
  setShowValues: React.Dispatch<React.SetStateAction<boolean>>;
  data: Comissao[];
}

const CustomComitions = ({
  data,
  showValues,
  setShowValues,
}: ICustomComitions) => {
  const toggleValues = () => {
    setShowValues(!showValues);
  };

  return (
    <Box>
      <Table variant="simple">
        <Thead
          h="60px"
          position="sticky"
          top="0"
          zIndex="1"
          bg="white"
          boxShadow="sm"
        >
          <Tr>
            <Th width="20%">Status</Th>
            <Th width="20%">Oportunidade</Th>
            <Th width="20%">Cliente</Th>
            <Th width="20%">Data prevista</Th>
            <Th isNumeric textAlign="center">
              <Flex gap="4px" align="center" justify={"center"} h={"fit-content"}>
                Valor{" "}
                <Box
                  cursor="pointer"
                  onClick={toggleValues}
                  fontSize="17px"
                  w={"fit-content"}
                >
                  {showValues ? <FaRegEye /> : <FaRegEyeSlash />}
                </Box>
              </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((item, index) => (
            <Tr key={index}>
              <Td width="20%" fontSize="14px">
                {item.statusPagamento}
              </Td>
              <Td width="20%" fontSize="14px">
                {item.idOportunidade}
              </Td>
              <Td width="20%" fontSize="14px">
                {item.cliente}
              </Td>
              <Td width="20%" fontSize="14px">
                {item.dataDePagamento}
              </Td>
              <Td width="20%" fontSize="14px" isNumeric>
                {showValues
                  ? `${formatMoney(item.valorHonorarios)}`
                  : "R$ •••••"}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default CustomComitions;
