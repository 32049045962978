import axios from "axios";

function handleUrl() {
  if (window.location.href.includes("localhost"))
    return "https://apistage.direcional.com.br/corretorapi/v1";

  if (window.location.href.includes("dev"))
    return "https://apidev.direcional.com.br/corretorapi/v1";

  if (window.location.href.includes("qa"))
    return "https://apihmg.direcional.com.br/corretorapi/v1";

  if (window.location.href.includes("stage"))
    return "https://apistage.direcional.com.br/corretorapi/v1";

  return "https://api.direcional.com.br/corretorapi/v1";
}

const api = axios.create({
  baseURL: handleUrl(),
  headers: {
    client_id: "b53793b2-7c67-42f4-ac30-06f3a6295179",
  },
});

export default api;
