import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { IDashboardAnaliseDeCredito } from "src/@types/Home";
import { theme } from "src/styles/theme";

type Props = {
  data: IDashboardAnaliseDeCredito[];
};

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];

const CustomCreditRatingByStatus = ({ data }: Props) => {
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const onMouseEnter = (dataIndex: number) => {
    setActiveIndex(dataIndex);
  };

  const onMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <Flex align="center" justify="space-between" mt={10}>
      {/* PieChart Responsivo */}
      {data?.length === 0 ? (
        <Flex align="center" justify="center" h={"190px"}>
          <Text w={"fit-content"} color={theme.text50}>
            Não há análise de crédito por status!
          </Text>
        </Flex>
      ) : (
        <>
          <Box position="relative" w="40%" h="190px">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  dataKey="quantidade"
                  nameKey="fase"
                  cx="50%"
                  cy="50%"
                  innerRadius={80}
                  outerRadius={90}
                  paddingAngle={0}
                  onMouseEnter={(_, index) => onMouseEnter(index)}
                  onMouseLeave={onMouseLeave}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      stroke={activeIndex === index ? "#646363" : "none"}
                      strokeWidth={activeIndex === index ? 2 : 1}
                    />
                  ))}
                </Pie>
                <Tooltip
                  wrapperStyle={{ zIndex: 10 }}
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      const { status, quantidade } = payload[0].payload;
                      return (
                        <Box
                          p={2}
                          bg="white"
                          boxShadow="md"
                          borderRadius="md"
                          border="1px solid #ddd"
                          zIndex={2}
                        >
                          <Text fontWeight="bold">{status}</Text>
                          <Text>{`Quantidade: ${quantidade}`}</Text>
                        </Box>
                      );
                    }
                    return null;
                  }}
                />
              </PieChart>
            </ResponsiveContainer>

            {/* Número centralizado */}
            <Box
              display={"flex"}
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontSize="2rem"
              fontWeight="semibold"
              color={theme.text}
              w={"fit-content"}
              zIndex={1}
            >
              {data.reduce((acc, curr) => acc + curr.quantidade, 0)}
            </Box>
          </Box>

          {/* Legenda */}
          <Box
            display={"flex"}
            flexDir={"column"}
            w="70%"
            h={"140px"}
            flexWrap={"wrap"}
            gap={"6px"}
          >
            {data.map(({ status }, index) => (
              <Flex key={index} align="center" mb={2} w={"50%"}>
                <Box
                  w={3}
                  h={3}
                  borderRadius="full"
                  bg={COLORS[index % COLORS.length]}
                  mr={3}
                ></Box>
                <Text fontSize="sm" w={"fit-content"} color={theme.text}>
                  {status}
                </Text>
              </Flex>
            ))}
          </Box>
        </>
      )}
    </Flex>
  );
};

export default CustomCreditRatingByStatus;
