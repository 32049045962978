import React from "react";
import useKey from "@hook/useKey";
import { LoginContext } from "@context/loginContext";
import Button from "@components/common/Button";
import InputEmail from "@components/Login/Inputs/InputEmail";
import InputPassword from "@components/Login/Inputs/InputPassword";
import { isEmail } from "@helpers/emailCheck";
import Spinner from "@components/common/Spinner";
import Box from "@components/Login/Box";
import Helper from "@components/Login/Helper";
import * as Styled from "./styles";
import { Flex, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

interface IProps {
  onClickForgotPassword: () => void;
  onClickFirstLogin: () => void;
}

const CurrentLogin = ({ onClickForgotPassword, onClickFirstLogin }: IProps) => {
  useKey("Enter", handleEnter);
  useKey("NumpadEnter", handleEnter);
  const {
    setPage,
    signIn,
    loadingLogin,
    errorLogin,
    setErrorLogin,
    isRemakeLogin,
    setIsRemakeLogin,
  } = React.useContext(LoginContext);
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  function handleEnter() {
    handleLogin();
  }

  const handleLogin = async () => {
    const data = { email, password };

    await signIn(data);
  };

  function titleButton() {
    if (errorLogin) return "Acesso Inválido";
    if (loadingLogin) return <Spinner />;
    return "Entrar";
  }

  return (
    <Flex flexDir={"column"} alignItems={"center"}>
      <Flex
        flexDir={"column"}
        alignItems={"center"}
        w={{ base: "80%", lg: "55%", xl: "80%" }}
        mb={"5px"}
      >
        <Flex flexDir={"column"} h={"80px"}>
          <InputEmail
            isLabel
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorLogin(false);
              setIsRemakeLogin(false);
            }}
            onInput={(e) => {
              setEmail((e.target as HTMLInputElement).value);
              setErrorLogin(false);
              setIsRemakeLogin(false);
            }}
            iconClick={() => {
              setEmail("");
              setErrorLogin(false);
              setIsRemakeLogin(false);
            }}
            error={errorLogin || isRemakeLogin}
          />
        </Flex>

        <Flex flexDir={"column"} h={"80px"} mt={"8px"}>
          <InputPassword
            isLabel
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorLogin(false);
              setIsRemakeLogin(false);
            }}
            onInput={(e) => {
              setPassword((e.target as HTMLInputElement).value);
              setErrorLogin(false);
              setIsRemakeLogin(false);
            }}
            error={errorLogin || isRemakeLogin}
          />
        </Flex>

        <Flex justifyContent={"flex-end"} h={"40px"} mt={"6px"}>
          <Text
            fontSize={"13px"}
            color={theme.primary}
            fontWeight={"medium"}
            cursor={"pointer"}
            w={"fit-content"}
            h={"fit-content"}
            onClick={() => {
              onClickForgotPassword();
              setErrorLogin(false);
              setIsRemakeLogin(false);
            }}
          >
            Esqueci minha senha
          </Text>
        </Flex>

        <Flex flexDir={"column"} h={"fit-content"}>
          <Button
            title={titleButton()}
            onClick={handleLogin}
            disabled={!isEmail(email) || loadingLogin || !password}
            error={errorLogin || isRemakeLogin}
          />
        </Flex>

        <Flex flexDir={"column"} justify={"flex-end"} h={"100px"}>
          {(errorLogin || isRemakeLogin) && (
            <Box
              width="92%"
              title={
                isRemakeLogin
                  ? "Houve atualização no seu cadastro"
                  : "Usuário e/ou senha inválidos:"
              }
              body={
                isRemakeLogin ? (
                  <Styled.ButtonFirstAccess>
                    Seus dados cadastrais foram atualizados no nosso sistema.
                    Por isso, será necessário que você clique no link{" "}
                    <span
                      onClick={() => {
                        onClickFirstLogin();
                        setErrorLogin(false);
                        setIsRemakeLogin(false);
                      }}
                    >
                      Primeiro acesso.
                    </span>
                  </Styled.ButtonFirstAccess>
                ) : (
                  <Styled.ButtonFirstAccess>
                    Caso tenha esquecido seus dados de acesso, clique no link{" "}
                    <span
                      onClick={() => {
                        onClickForgotPassword();
                        setErrorLogin(false);
                      }}
                    >
                      Esqueceu sua senha.
                    </span>
                  </Styled.ButtonFirstAccess>
                )
              }
            />
          )}
        </Flex>
      </Flex>
      <Helper />
    </Flex>
  );
};

export default CurrentLogin;
