import { Box, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import Spinner from "@components/common/Spinner";
import { LoginContext } from "@context/loginContext";
import { OportunityContext } from "@context/oportunityContex";
import { UsuallyContext } from "@context/usuallyContex";
import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { FaAward } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IDashboardOportunidade } from "src/@types/Home";
import { IconHeadset } from "src/assets/iconHeadset";
import { IconInfo } from "src/assets/iconInfo";
import { IconSchedule } from "src/assets/iconSchedule";
import { theme } from "src/styles/theme";

type Props = {
  data: IDashboardOportunidade[];
};

const CustomResume = ({ data }: Props) => {
  const navigate = useNavigate();
  const { setPageSidebar } = React.useContext(LoginContext);
  const { setHashSalesSidebar } = React.useContext(UsuallyContext);
  const { setFaseMyOportunity } = React.useContext(OportunityContext);

  const handleRedirect = (fase: string) => {
    navigate("/oportunidades");
    setPageSidebar("Minhas oportunidades");
    localStorage.setItem("@option", "Minhas oportunidades");
    setHashSalesSidebar("MyOportunityComponent");
    setFaseMyOportunity(fase);
  };

  const handleRanking = (ranking: string) => {
    if (ranking === "Ouro") return "#E69C08";
    else if (ranking === "Prata") return "#8F8F8F";
    else if (ranking === "Bronze") return "#D6763A";
    else return undefined;
  };

  const handleIconFase = (fase: string) => {
    if (fase.includes("atendimento")) {
      return <IconHeadset width="22" height="22" />;
    }
    // else if (fase.includes("aprovadas")) {
    //   return <IconInfo width="22" height="22" />;
    // }
    else if (fase.includes("Cliente Ouro")) {
      return <IconInfo width="22" height="22" />;
    } else {
      return <IconSchedule width="22" height="22" />;
    }
  };

  return (
    <Flex h={"calc(100% - 40px)"} justifyContent={"center"} align={"center"}>
      {data.length < 1 ? (
        <>
          <Text w={"fit-content"} color={theme.text50}>
            Não há oportunidades pendentes!
          </Text>
        </>
      ) : (
        <>
          {data.map(({ fase, quantidade }, index) => (
            <VStack key={index}>
              <Flex align={"center"} justify={"center"} gap={"4px"}>
                <Box
                  display={"flex"}
                  w={"fit-content"}
                  h={"30px"}
                  justifyContent={"center"}
                  alignItems={"flex-end"}
                >
                  {handleIconFase(fase)}
                </Box>
                <Text fontSize="4xl" fontWeight="semibold" w={"fit-content"}>
                  {quantidade}
                </Text>
              </Flex>

              <Flex align={"center"} justify={"center"} h={"fit-content"}>
                <Text
                  fontSize="12px"
                  color={theme.text50}
                  w={"fit-content"}
                  cursor={"pointer"}
                  _hover={{
                    color: theme.primary,
                    fontWeight: "medium",
                  }}
                  onClick={() => handleRedirect(fase)}
                >
                  {fase}{" "}
                </Text>
                <Icon fontSize="24px" w={"fit-content"} color={theme.text}>
                  <BiChevronRight />
                </Icon>
              </Flex>
            </VStack>
          ))}
        </>
      )}
    </Flex>
  );
};

export default CustomResume;
