import React from "react";
import * as Styled from "./stylesStepTab";
import Tab from "@components/Sales/Tab";
import SeeScheduleComponent from "./Schedule/SeeScheduleComponent";
import TasksCallsComponent from "./TasksCalls/TasksCallsComponent";
import { IActivityComponent } from "src/@types/Sales";
import { OportunityContext } from "@context/oportunityContex";

const ActivityComponent = ({
  indexFirstTab,
  navigateCreateSchedule,
  navigateCreateTask,
  successCreateSchedule,
  successCreateTaskCall,
  updateData,

  showDetailTaskCall,
  setShowDetailTaskCall,
  showDetailSchedule,
  setShowDetailSchedule,
}: IActivityComponent) => {
  const { indexTabActivityComponent, setIndexTabActivityComponent } =
    React.useContext(OportunityContext);

  const tabLabels = ["Tarefas e chamadas", "Agendamentos"];
  const tabContents = {
    "Tarefas e chamadas": (
      <TasksCallsComponent
        index={indexTabActivityComponent}
        indexFirstTab={indexFirstTab}
        navigateCreateTask={navigateCreateTask}
        successCreateTaskCall={successCreateTaskCall}
        updateData={updateData}
        showDetailTaskCall={showDetailTaskCall}
        setShowDetailTaskCall={setShowDetailTaskCall}
      />
    ),
    Agendamentos: (
      <SeeScheduleComponent
        index={indexTabActivityComponent}
        indexFirstTab={indexFirstTab}
        navigateCreateSchedule={navigateCreateSchedule}
        successCreateSchedule={successCreateSchedule}
        updateData={updateData}
        showDetailSchedule={showDetailSchedule}
        setShowDetailSchedule={setShowDetailSchedule}
      />
    ),
  };

  return (
    <Styled.ContainerActivity>
      <Styled.TitleActivity>
        <h1>Atividades da oportunidade</h1>
      </Styled.TitleActivity>

      <Styled.ContainerActivitySubTab>
        <Tab
          widthXl="55%"
          width="70%"
          widthMd="75%"
          widthSm="100%"
          widthBase="100%"
          fontSize="13px"
          index={indexTabActivityComponent}
          setIndex={setIndexTabActivityComponent}
          tabLabels={tabLabels}
          tabContents={tabContents}
        />
      </Styled.ContainerActivitySubTab>
    </Styled.ContainerActivity>
  );
};

export default ActivityComponent;
