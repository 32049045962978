const regionalMap = [
  "AM",
  "CE",
  "DF",
  "MG",
  "PE",
  "RJ",
  "SP Capital",
  "SP Interior",
];
const regionalCommercialMap = [
  "AM",
  "BA",
  "CE",
  "DF",
  "MG",
  "PE",
  "RJ",
  "SP Capital",
  "SP Interior",
];
const schoolingTypes = [
  "Fundamental Completo",
  "Médio Completo",
  "Superior Incompleto",
  "Superior Completo",
  "Curso Técnico",
  "Tecnólogo",
  "Pós Graduação",
  "Mestrado",
  "Doutorado",
];
const ufNames = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];
const banks = [
  "001 – Banco do Brasil S.A.",
  "004 - BANCO DO NORDESTE DO BRASIL S.A.",
  "033 – Banco Santander (Brasil) S.A.",
  "070 - BCO BRB SA - BRASILIA",
  "077 - BCO INTERMEDIUM SA",
  "079 - Banco Original Agro",
  "092 - BANCO BRK",
  "104 – Caixa Econômica Federal",
  "121 - Banco Agiplan",
  "197 – Stone Pagamentos S.A.",
  "208– Banco BTG Pactual",
  "212 - Banco Original S.A.",
  "218 – Banco Bonsucesso SA",
  "237 – Banco Bradesco S.A.",
  "246 - Banco ABC Brasil S.A.",
  "260 – Banco Nubank",
  "290 – PagSeguro Internet SA",
  "318 - BCO BMG COMERCIAL S.A",
  "323 – Mercado Pago",
  "336 - BANCO C6 S.A.",
  "340 – Super digital",
  "341 – Banco Itaú S.A.",
  "356 – Banco Real S.A. (antigo)",
  "364 - Gerencianet",
  "380 – PicPay",
  "389 – Banco Mercantil do Brasil S.A.",
  "399 – HSBC Bank Brasil S.A. – Banco Múltiplo",
  "403 – CORA SOCIEDADE DE CR",
  "413 – BV",
  "422 – Banco Safra S.A.",
  "453 – Banco Rural S.A.",
  "473 - Banco Caixa Geral - Brasil S.A.",
  "623 – Banco Panamericano S.A",
  "633 – Banco Rendimento S.A.",
  "637 - Bco Sofisa SA.",
  "652 – Itaú Unibanco Holding S.A.",
  "655 – Banco Votorantim S.A.",
  "735 - BANCO POTTENCIAL S.A.",
  "745 – Banco Citibank S.A.",
  "746 - BCO MODAL SA.",
  "748 – BCO COOP. SICREDI SA",
  "756 – Banco SICCOB S.A",
];
const tshirtSizes = ["PP", "P", "M", "G", "GG", "XGG"];
const civilStatuses = ["Solteiro(a)", "Casado(a)", "Divorciado(a)", "Viúvo(a)"];
const nationalities = ["Brasileiro", "Estrangeira"];
const regionalEnterprises = [
  "DF Riva",
  "DF Direcional",
  "MG Riva",
  "MG Direcional",
  "PE Riva",
  "PE Direcional",
  "CE Riva",
  "CE Direcional",
  "AM Riva",
  "AM Direcional",
  "RJ Riva",
  "RJ Direcional",
  "SP Capital Direcional",
  "SP Capital Riva",
  "SP Interior Direcional",
  "SP Interior Riva",
  "Todos os empreendimentos",
];
const nationalitiesMap = [
  "Afegão",
  "Africano",
  "Alemão",
  "Americano",
  "Árabe",
  "Argentino",
  "Asiático",
  "Australiano",
  "Austríaco",
  "Belga",
  "Boliviano",
  "Brasileiro",
  "Britânico",
  "Búlgaro",
  "Camaronês",
  "Cambojano",
  "Canadense",
  "Chileno",
  "Chinês",
  "Colombiano",
  "Coreano",
  "Croata",
  "Cubano",
  "Dinamarquês",
  "Dominicano",
  "Egípcio",
  "Equatoriano",
  "Escocês",
  "Eslovaco",
  "Espanhol",
  "Europeu",
  "Filipino",
  "Finlandês",
  "Francês",
  "Grego",
  "Haitiano",
  "Holandês",
  "Hondurenho",
  "Húngaro",
  "Indiano",
  "Inglês",
  "Iraniano",
  "Iraquiano",
  "Irlandês",
  "Islandês",
  "Israelense",
  "Italiano",
  "Jamaicano",
  "Japonês",
  "Libanês",
  "Mexicano",
  "Nigeriano",
  "Norueguês",
  "Paquistanês",
  "Paraguaio",
  "Peruano",
  "Polonês",
  "Português",
  "Queniano",
  "Romeno",
  "Russo",
  "Senegalês",
  "Sírio",
  "Sueco",
  "Sul-Africano",
  "Sul-Coreano",
  "Turco",
  "Ucraniano",
  "Uruguaio",
  "Venezuelano",
];

export const optionRegional = regionalMap.flatMap((regional) => [
  {
    id: `${regional} Riva`,
    name: `${regional} Riva`,
  },
  {
    id: `${regional} Direcional`,
    name: `${regional} Direcional`,
  },
]);

export const optionCommercialRegional = regionalCommercialMap.map(
  (regional) => ({
    id: regional,
    name: regional,
  })
);

export const optionTema = [
  {
    id: "1",
    name: "Primeiro Acesso/Login",
  },
];

export const optionSchooling = schoolingTypes.map((type) => ({
  id: type,
  name: type,
}));

export const optionTshirt = tshirtSizes.map((size, index) => ({
  id: (index + 1).toString(),
  name: size,
}));

export const optionCivilStatus = civilStatuses.map((status, index) => ({
  id: (index + 1).toString(),
  name: status,
}));

export const optionNationality = nationalities.map((nationality, index) => ({
  id: (index + 1).toString(),
  name: nationality,
}));

export const optionUF = ufNames.map((name, index) => ({
  id: (index + 1).toString(),
  name: name,
}));

export const optionBank = banks.map((name, index) => ({
  id: (index + 1).toString(),
  name,
}));

export const optionCommercialRegionalSP = [
  {
    id: "1",
    name: "SP Capital",
  },
  {
    id: "2",
    name: "SP Interior",
  },
];

export const optionTypeAccount = [
  {
    id: "1",
    name: "Conta Corrente",
  },
  {
    id: "2",
    name: "Conta Poupança",
  },
];

export const optionTypeCorretor = [
  {
    id: "1",
    name: "Direcional Vendas - GRI(CLT)",
  },
  {
    id: "2",
    name: "Direcional Vendas - Autônomos",
  },
  {
    id: "3",
    name: "Direcional Vendas - Parceiros (Externo)",
  },
];

export const optionComissionBillingCLT = [
  {
    id: "1",
    name: "Folha de Pagamento - RM",
  },
];

export const optionComissionBillingAutonomous = [
  {
    id: "1",
    name: "Ordem de Faturamento (Direcional Corretora)",
  },
  {
    id: "2",
    name: "PJ / Empresa Própria",
  },
];

export const optionComissionBillingPartner = [
  {
    id: "1",
    name: "PJ / Empresa Própria",
  },
];

export const optionRegionalEnterprises = regionalEnterprises.map((name) => ({
  id: name,
  name,
}));

export const optionOriginAccount = [
  {
    id: "Oferta do corretor",
    name: "Oferta do corretor",
  },
  {
    id: "Leads autorais",
    name: "Leads autorais",
  },
];

export const optionTreatment = [
  {
    id: "Sr",
    name: "Sr.",
  },
  {
    id: "Ms",
    name: "Ms.",
  },
  {
    id: "Sra",
    name: "Sra.",
  },
  {
    id: "Dr",
    name: "Dr.",
  },
  {
    id: "Prof",
    name: "Prof.",
  },
  {
    id: "Srta",
    name: "Srta.",
  },
];

export const optionBusinessUnity = [
  {
    id: "Riva",
    name: "Riva",
  },
  {
    id: "Direcional",
    name: "Direcional",
  },
];

export const optionIncome = [
  {
    id: "nao informada",
    name: "Não informada",
  },
  {
    id: "2400",
    name: "Até R$ 2.400,00",
  },
  {
    id: "3000",
    name: "De R$ 2.400,01 até R$ 3.000,00",
  },
  {
    id: "3700",
    name: "De R$ 3.000,01 até R$ 3.700,00",
  },
  {
    id: "4400",
    name: "De R$ 3.700,01 até R$ 4.400,00",
  },
  {
    id: "8000",
    name: "De R$ 4.400,01 até R$ 8.000,00",
  },
  {
    id: "10000",
    name: "De R$ 8.000,01 até R$ 10.000,00",
  },
  {
    id: "Acima 10000",
    name: "Acima de R$ 10.000,01",
  },
];

export const optionBestHours = [
  {
    id: "Após horário comercial",
    name: "Após horário comercial",
  },
  {
    id: "Durante horário comercial",
    name: "Durante horário comercial",
  },
  {
    id: "Horário do almoço",
    name: "Horário do almoço",
  },
  {
    id: "Manhã",
    name: "Manhã",
  },
  {
    id: "Tarde",
    name: "Tarde",
  },
];

export const optionPurposePurchase = [
  {
    id: "Para investir",
    name: "Para investir",
  },
  {
    id: "Para morar",
    name: "Para morar",
  },
  {
    id: "Outras",
    name: "Outras",
  },
];

export const optionDetailsLive = [
  {
    id: "Aumento da família",
    name: "Aumento da família",
  },
  {
    id: "Downgrade",
    name: "Downgrade",
  },
  {
    id: "Matrimônio",
    name: "Matrimônio",
  },
  {
    id: "Mudança de cidade",
    name: "Mudança de cidade",
  },
  {
    id: "Upgrade",
    name: "Upgrade",
  },
];

export const optionNumberKids = [
  {
    id: "Não tem",
    name: "Não tem",
  },
  {
    id: "1",
    name: "1",
  },
  {
    id: "2",
    name: "2",
  },
  {
    id: "Mais de 2",
    name: "Mais de 2",
  },
];

export const optionNumberRooms = [
  {
    id: "1",
    name: "1",
  },
  {
    id: "2",
    name: "2",
  },
  {
    id: "3",
    name: "3",
  },
  {
    id: "4",
    name: "4",
  },
  {
    id: "Cobertura",
    name: "Cobertura",
  },
];

export const optionNumberVacancy = [
  {
    id: "1",
    name: "1",
  },
  {
    id: "2",
    name: "2",
  },
  {
    id: "3",
    name: "3",
  },
];

export const optionNacionality = nationalitiesMap.map((name, index) => ({
  id: (index + 1).toString(),
  name,
}));

export const optionAgeRange = [
  {
    id: "Até 20 anos",
    name: "Até 20 anos",
  },
  {
    id: "21 a 30 anos",
    name: "21 a 30 anos",
  },
  {
    id: "31 a 40 anos",
    name: "31 a 40 anos",
  },
  {
    id: "41 a 50 anos",
    name: "41 a 50 anos",
  },
  {
    id: "Acima de 50 anos",
    name: "Acima de 50 anos",
  },
];

export const optionSex = [
  {
    id: "Feminino",
    name: "Feminino",
  },
  {
    id: "Masculino",
    name: "Masculino",
  },
];

export const optionCivilStatusSales = [
  {
    id: "Casado(a)",
    name: "Casado(a)",
  },
  {
    id: "Divorciado(a)",
    name: "Divorciado(a)",
  },
  {
    id: "Solteiro(a)",
    name: "Solteiro(a)",
  },
  {
    id: "Viúvo(a)",
    name: "Viúvo(a)",
  },
  {
    id: "União Estável",
    name: "União Estável",
  },
  {
    id: "N/A",
    name: "N/A",
  },
];

export const optionMarriageRegime = [
  {
    id: "Comunhão parcial",
    name: "Comunhão parcial",
  },
  {
    id: "Comunhão universal",
    name: "Comunhão universal",
  },
  {
    id: "Participação final dos aquestos",
    name: "Participação final dos aquestos",
  },
  {
    id: "Separação total de bens",
    name: "Separação total de bens",
  },
];

export const optionSpouse = [
  {
    id: "Sim",
    name: "Sim",
  },
  {
    id: "Não",
    name: "Não",
  },
];

export const optionMakeUpIncome = [
  {
    id: "Sim",
    name: "Sim",
  },
  {
    id: "Não",
    name: "Não",
  },
];

export const optionTypeWork = [
  {
    id: "Autônomo",
    name: "Autônomo",
  },
  {
    id: "Formal",
    name: "Formal",
  },
  {
    id: "Informal",
    name: "Informal",
  },
];

export const optionFase = [
  { id: "Todas", name: "Todas" },
  {
    id: "Aguardando atendimento Corretor",
    name: "Aguardando atendimento Corretor",
  },
  {
    id: "Análise de Crédito Realizada",
    name: "Análise de Crédito Realizada",
  },
  { id: "Análise SAFI", name: "Análise SAFI" },
  { id: "Aprovado SAFI", name: "Aprovado SAFI" },
  { id: "Cancelado", name: "Cancelado" },
  {
    id: "Contrato com pendência comercial",
    name: "Contrato com pendência comercial",
  },
  { id: "Contrato comunicado", name: "Contrato comunicado" },
  { id: "Contrato gerado", name: "Contrato gerado" },
  { id: "Distratado", name: "Distratado" },
  { id: "Em Análise de Crédito", name: "Em Análise de Crédito" },
  { id: "Em atendimento", name: "Em atendimento" },
  { id: "Fechado e ganho", name: "Fechado e ganho" },
  { id: "Fechado e perdido", name: "Fechado e perdido" },
  { id: "Proposta", name: "Proposta" },
  { id: "Proposta Aprovada", name: "Proposta Aprovada" },
  { id: "Proposta Reprovada", name: "Proposta Reprovada" },
  { id: "Rejeitado SAFI", name: "Rejeitado SAFI" },
  { id: "Reprovado Comissões", name: "Reprovado Comissões" },
  { id: "Repassado", name: "Repassado" },
  { id: "Visita Agendada", name: "Visita Agendada" },
  { id: "Visita Realizada", name: "Visita Realizada" },
];

export const optionOportunity = [
  { id: "Todas", name: "Todas" },
  { id: "Nova", name: "Nova" },
  { id: "Antiga", name: "Antiga" },
];

export const optionTypeVisit = [
  { id: "Indicação do corretor", name: "Indicação do corretor" },
  { id: "Retorno", name: "Retorno" },
];

export const optionStatusTask = [
  { id: "Não iniciado", name: "Não iniciado" },
  { id: "Em andamento", name: "Em andamento" },
  { id: "Concluído", name: "Concluído" },
  { id: "Aguardando outra pessoa", name: "Aguardando outra pessoa" },
  { id: "Adiado", name: "Adiado" },
];

export const optionNumbersTry = [
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
];

export const optionBankCreditRating = [
  {
    id: "Banco do Brasil",
    recordTypeId: "012f1000000LG8BAAW",
    name: "Banco do Brasil",
    code: 1,
  },
  {
    id: "Banco Inter",
    recordTypeId: "0123Z000000n90ZQAQ",
    name: "Banco Inter",
    code: 77,
  },
  {
    id: "Bradesco",
    recordTypeId: "012f1000000LG8CAAW",
    name: "Bradesco",
    code: 237,
  },
  { id: "Caixa", recordTypeId: "012f1000000LG8DAAW", name: "Caixa", code: 104 },
  { id: "Itaú", recordTypeId: "012f1000000LG8EAAW", name: "Itaú", code: 341 },
  {
    id: "Santander",
    recordTypeId: "012f1000000LG8FAAW",
    name: "Santander",
    code: 33,
  },
  {
    id: "Avaliação Interna",
    recordTypeId: "0123Z000001Dkx3QAC",
    name: "Avaliação Interna",
    code: null,
  },
];

export const optionTypeRelationship = [
  { id: "Compositor de Renda", name: "Compositor de Renda" },
  { id: "Cônjuge", name: "Cônjuge" },
  { id: "Representante Legal", name: "Representante Legal" },
  { id: "Fiador", name: "Fiador" },
];
export const optionTypeSale = [
  { id: "Venda Nova", name: "Venda Nova" },
  { id: "Migração Down", name: "Migração Down" },
  { id: "Migração UP", name: "Migração UP" },
  { id: "Permuta Serviços", name: "Permuta Serviços" },
  { id: "Permuta Terreno", name: "Permuta Terreno" },
  { id: "Doação", name: "Doação" },
  { id: "Venda Colaborador", name: "Venda Colaborador" },
];

export const optionProfessions = [
  { id: "1", name: "Administração de edifícios" },
  { id: "2", name: "Administração econômico-financeira" },
  { id: "3", name: "Administradores" },
  { id: "4", name: "Administradores de redes e BD" },
  { id: "5", name: "Advogados" },
  { id: "6", name: "Afiadores e polidores de metais" },
  { id: "7", name: "Agentes comunitários de saúde e afins" },
  { id: "8", name: "Agentes da saúde e do meio ambiente" },
  { id: "9", name: "Agentes fiscais metrológicos" },
  { id: "10", name: "Agentes investigação e identificação" },
  { id: "11", name: "Ajudantes de obras civis" },
  { id: "12", name: "Ajustadores mecânicos polivalentes" },
  { id: "13", name: "Alimentadores de linhas de produção" },
  { id: "14", name: "Almoxarifes e armazenistas" },
  { id: "15", name: "Analistas de comércio exterior" },
  { id: "16", name: "Analistas de sistemas computacionais" },
  { id: "17", name: "Antropológica sociológica" },
  { id: "18", name: "Aplicadores de materiais isolantes" },
  { id: "19", name: "Aplicadores revestimentos cerâmicos" },
  { id: "20", name: "Apontadores e conferentes" },
  { id: "21", name: "Aposentado" },
  { id: "22", name: "Apresentadores de espetáculos" },
  { id: "23", name: "Aquaviários" },
  { id: "24", name: "Árbitros desportivos" },
  { id: "25", name: "Arquitetos e urbanistas" },
  { id: "26", name: "Arquivistas e museólogos" },
  { id: "27", name: "Artesanais na conservação de alimentos" },
  { id: "28", name: "Artesãos de metais preciosos" },
  { id: "29", name: "Artistas da dança" },
  { id: "30", name: "Artistas de circo" },
  { id: "31", name: "Artistas visuais ou desenhistas industriais" },
  { id: "32", name: "Assistentes sociais" },
  { id: "33", name: "Astrólogos e numerólogos" },
  { id: "34", name: "Atletas profissionais" },
  { id: "35", name: "Atores" },
  { id: "36", name: "Auditores fiscais da previdência social" },
  { id: "37", name: "Auditores fiscais do trabalho" },
  { id: "38", name: "Auditores fiscais receita federal" },
  { id: "39", name: "Auxiliares de contabilidade" },
  { id: "40", name: "Auxiliares de laboratório da saúde" },
  { id: "41", name: "Auxiliares dos serviços funerários" },
  { id: "42", name: "Auxiliares nos serviços de alimentação" },
  { id: "43", name: "Auxiliares serviços de documentação" },
  { id: "44", name: "Avaliadores e orientadores de ensino" },
  { id: "45", name: "Avicultura e cunicultura" },
  { id: "46", name: "Beneficiamento de minérios" },
  { id: "47", name: "Beneficiamento de pedras ornamentais" },
  { id: "48", name: "Biólogos e afins" },
  { id: "49", name: "Biomédicos" },
  { id: "50", name: "Biotecnologia" },
  { id: "51", name: "Bombeiros e salva-vidas" },
  { id: "52", name: "Cabos e soldados bombeiros militar" },
  { id: "53", name: "Cabos e soldados da polícia militar" },
  { id: "54", name: "Caixas e bilheteiros (exceto banco)" },
  { id: "55", name: "Camareiros, roupeiros e afins" },
  { id: "56", name: "Capitães da polícia militar" },
  { id: "57", name: "Captadores de imagens em movimento" },
  { id: "58", name: "Carpinteiros de carrocerias e carretas" },
  { id: "59", name: "Carpinteiros navais" },
  { id: "60", name: "Carteiros e operadores postais" },
  { id: "61", name: "Carvoejadores" },
  { id: "62", name: "Catadores de material reciclável" },
  { id: "63", name: "Cenógrafos" },
  { id: "64", name: "Cigarreiros e beneficiadores de fumo" },
  { id: "65", name: "Cirurgiões-dentistas" },
  { id: "66", name: "Cobradores e afins" },
  { id: "67", name: "Coletadores de apostas e de jogos" },
  { id: "68", name: "Coloristas" },
  { id: "69", name: "Compradores" },
  { id: "70", name: "Condutores de processos robotizados" },
  { id: "71", name: "Confeccionadores instrumentos musicais" },
  { id: "72", name: "Conservação e qualidade alimentos" },
  { id: "73", name: "Conservadores de vias permanentes" },
  { id: "74", name: "Conservadores-restauradores" },
  { id: "75", name: "Contadores e afins" },
  { id: "76", name: "Contínuos" },
  { id: "77", name: "Corretores de imóveis" },
  { id: "78", name: "Corretores de seguros" },
  { id: "79", name: "Corretores valores e ativos financeiros" },
  { id: "80", name: "Cozinheiros" },
  { id: "81", name: "Criação de insetos e animais úteis" },
  { id: "82", name: "Criadores de animais aquáticos" },
  { id: "83", name: "Cuidadores crianças, adultos e idosos" },
  { id: "84", name: "Dançarinos tradicionais e populares" },
  { id: "85", name: "Defensores públicos" },
  { id: "86", name: "Degustação e classificação de grãos e afins" },
  { id: "87", name: "Delegados de polícia" },
  { id: "88", name: "Dentista" },
  { id: "89", name: "Desenhistas projetista" },
  { id: "90", name: "Desenhistas técnicos, em geral" },
  { id: "91", name: "Designer de interiores-nível superior" },
  { id: "92", name: "Designers de interiores" },
  { id: "93", name: "Despachantes aduaneiros" },
  { id: "94", name: "Despachantes documentalistas" },
  { id: "95", name: "Diretores de espetáculos e afins" },
  { id: "96", name: "Diretores de operações de obras" },
  { id: "97", name: "Diretores de operações empresa" },
  { id: "98", name: "Diretores de produção e operações" },
  { id: "99", name: "Diretores gerais" },
  { id: "100", name: "Dirigentes administração pública" },
  { id: "101", name: "Dirigentes do serviço público" },
  { id: "102", name: "Dirigentes e Administradores" },
  { id: "103", name: "Economistas" },
  { id: "104", name: "Editores" },
  { id: "105", name: "Educação física" },
  { id: "106", name: "Eletricistas manutenção eletroeletrônica" },
  { id: "107", name: "Embalagem e etiquetagem" },
  { id: "108", name: "Empresário" },
  { id: "109", name: "Encadernadores de livros" },
  { id: "110", name: "Encanadores ou instaladores tubulações" },
  { id: "111", name: "Encarregado" },
  { id: "112", name: "Enfermeiros" },
  { id: "113", name: "Engenheiros agrimensores" },
  { id: "114", name: "Engenheiros agrossilvipecuários" },
  { id: "115", name: "Engenheiros cartógrafos" },
  { id: "116", name: "Engenheiros civis e afins" },
  { id: "117", name: "Engenheiros de minas" },
  { id: "118", name: "Engenheiros de produção" },
  { id: "119", name: "Engenheiros de qualidade e segurança" },
  { id: "120", name: "Engenheiros eletricistas e eletrônicos" },
  { id: "121", name: "Engenheiros em computação" },
  { id: "122", name: "Engenheiros mecânicos" },
  { id: "123", name: "Engenheiros mecatrônicos" },
  { id: "124", name: "Engenheiros metalurgistas" },
  { id: "125", name: "Engenheiros químicos" },
  { id: "126", name: "Enólogos, perfumistas e aromistas" },
  { id: "127", name: "Entrevistadores e recenseadores" },
  { id: "128", name: "Escriturários de serviços bancários" },
  { id: "129", name: "Escriturários em geral e agentes" },
  { id: "130", name: "Esotéricos e paranormais" },
  { id: "131", name: "Extração de minerais" },
  { id: "132", name: "Extrativistas e reflorestadores" },
  { id: "133", name: "Fabricação (plásticos e borracha)" },
  { id: "134", name: "Fabricação de bebidas" },
  { id: "135", name: "Fabricação de laticínios e afins" },
  { id: "136", name: "Fabricação e conservação de alimentos" },
  { id: "137", name: "Fabricação munição/explosivos químicos" },
  { id: "138", name: "Farmacêuticos" },
  { id: "139", name: "Ferramenteiros e afins" },
  { id: "140", name: "Filólogos, tradutores e intérpretes" },
  { id: "141", name: "Filósofos" },
  { id: "142", name: "Fiscais e cobradores transp. coletivos" },
  { id: "143", name: "Fiscais tributos estaduais e municipais" },
  { id: "144", name: "Físicos" },
  { id: "145", name: "Fisioterapeutas" },
  { id: "146", name: "Fonoaudiólogos" },
  { id: "147", name: "Forneiros metalúrgicos" },
  { id: "148", name: "Fotógrafos profissionais" },
  { id: "149", name: "Funcionário Público" },
  { id: "150", name: "Garçons, barmen e outros" },
  { id: "151", name: "Garimpeiros e operadores de salinas" },
  { id: "152", name: "Geofísicos e afins" },
  { id: "153", name: "Geólogos, oceanógrafos e geofísicos" },
  { id: "154", name: "Gerentes administrativos" },
  { id: "155", name: "Gerentes de comercialização" },
  { id: "156", name: "Gerentes de manutenção" },
  { id: "157", name: "Gerentes de obras Construtoras" },
  { id: "158", name: "Gerentes de operações comerciais" },
  { id: "159", name: "Gerentes de operações de serviços" },
  { id: "160", name: "Gerentes de pesquisa e desenvolvimento" },
  { id: "161", name: "Gerentes de produção e operações" },
  { id: "162", name: "Gerentes de recursos humanos" },
  { id: "163", name: "Gerentes de suprimentos e afins" },
  { id: "164", name: "Gerentes financeiros e de riscos" },
  { id: "165", name: "Gerentes marketing e comunicação" },
  { id: "166", name: "Gerentes operações Ass. técnica" },
  { id: "167", name: "Gerentes tecnologia da informação" },
  { id: "168", name: "Gesseiros" },
  { id: "169", name: "Gestores públicos" },
  { id: "170", name: "Guias de turismo" },
  { id: "171", name: "Industrialização de café e produtos afins" },
  { id: "172", name: "Inspetores/revisores produção têxtil" },
  { id: "173", name: "Instalações de captação e esgotos" },
  { id: "174", name: "Instalações de sinterização" },
  { id: "175", name: "Instalações elétricas" },
  { id: "176", name: "Instalações refrigeração e ar-condicionado" },
  { id: "177", name: "Instaladores de comunicação de dados" },
  { id: "178", name: "Instaladores de produtos e acessórios" },
  { id: "179", name: "Instaladores equip. Refrigeração" },
  { id: "180", name: "Instaladores sistemas de segurança" },
  { id: "181", name: "Instaladores telecomunicações" },
  { id: "182", name: "Instrutores de ensino profissional" },
  { id: "183", name: "Joalheiros e lapidadores de gemas" },
  { id: "184", name: "Laboratoristas industriais auxiliares" },
  { id: "185", name: "Lavadores e passadores de roupa" },
  { id: "186", name: "Legisladores" },
  { id: "187", name: "Leiloeiros e avaliadores" },
  { id: "188", name: "Locutores, comentaristas e repórteres" },
  { id: "189", name: "Lubrificadores" },
  { id: "190", name: "Médicos" },
  { id: "191", name: "Membros do ministério público" },
  { id: "192", name: "Mercado e negócios" },
  { id: "193", name: "Metrologia" },
  { id: "194", name: "Ministros de culto e missionários" },
  { id: "195", name: "Modelos" },
  { id: "196", name: "Montadores aparelhos telecomunicações" },
  { id: "197", name: "Montadores de estruturas aeronaves" },
  { id: "198", name: "Montadores de máquinas" },
  { id: "199", name: "Montadores de móveis madeira" },
  { id: "200", name: "Montadores de sistemas aeronaves" },
  { id: "201", name: "Montadores de veículos automotores" },
  { id: "202", name: "Montadores estrut. concreto armado" },
  { id: "203", name: "Montagem de estruturas" },
  { id: "204", name: "Montagem estruturas metálicas" },
  { id: "205", name: "Montagens e instalações eletroeletrônicas" },
  { id: "206", name: "Mordomos e governantas" },
  { id: "207", name: "Motociclistas entregas rápidas" },
  { id: "208", name: "Motoristas de ônibus" },
  { id: "209", name: "Motoristas de veículos" },
  { id: "210", name: "Motoristas de veículos de cargas" },
  { id: "211", name: "Músicos compositores" },
  { id: "212", name: "Músicos intérpretes" },
  { id: "213", name: "Nutricionistas" },
  { id: "214", name: "Oficiais de convés e afins" },
  { id: "215", name: "Oficiais de máquinas da marinha" },
  { id: "216", name: "Oficiais generais das forças armadas" },
  { id: "217", name: "Oficiais intermediários bombeiros" },
  { id: "218", name: "Oficiais superiores bombeiros" },
  { id: "219", name: "Oficiais superiores da polícia militar" },
  { id: "220", name: "Operação de máquinas de concreto" },
  { id: "221", name: "Operação de máquinas terraplenagem" },
  { id: "222", name: "Operadores costurar calçados" },
  { id: "223", name: "Operadores da fiação" },
  { id: "224", name: "Operadores de equipamentos" },
  { id: "225", name: "Operadores de máquinas a vapor" },
  { id: "226", name: "Operadores de máquinas-convencionais" },
  { id: "227", name: "Operadores de telefonia" },
  { id: "228", name: "Operadores de telemarketing" },
  { id: "229", name: "Operadores de veículos subaquáticos" },
  { id: "230", name: "Operadores do comércio" },
  { id: "231", name: "Operadores e distribuição de energias" },
  { id: "232", name: "Operadores máquinas de usinagem" },
  { id: "233", name: "Operadores rede teleprocessamento" },
  { id: "234", name: "Operadores tear e máquinas similares" },
  { id: "235", name: "Operadores veículos sobre trilhos e cabos" },
  { id: "236", name: "Outros trabalhadores dos serviços" },
  { id: "237", name: "Padeiros, confeiteiros e afins" },
  { id: "238", name: "Peritos criminais" },
  { id: "239", name: "Pescadores polivalentes" },
  { id: "240", name: "Pescadores profissionais" },
  { id: "241", name: "Pesquisadores Cienc. da agricultura" },
  { id: "242", name: "Pesquisadores ciências biológicas" },
  { id: "243", name: "Pesquisadores ciências naturais" },
  { id: "244", name: "Pesquisadores das ciências da saúde" },
  { id: "245", name: "Pesquisadores das ciências sociais" },
  { id: "246", name: "Pesquisadores engenharia" },
  { id: "247", name: "Pesquisadores exatas" },
  { id: "248", name: "Pesquisadores humanas" },
  { id: "249", name: "Pesquisadores tecnologia" },
  { id: "250", name: "Pilotagem aeronáutica" },
  { id: "251", name: "Pilotos de aviação comercial" },
  { id: "252", name: "Pintores de obras e revestidores" },
  { id: "253", name: "Pintura de equipamentos e veículos" },
  { id: "254", name: "Policiais, guardas-civis municipais" },
  { id: "255", name: "Porteiros e vigias" },
  { id: "256", name: "Preparação da confecção de roupas" },
  { id: "257", name: "Procuradores da assistência judiciária" },
  { id: "258", name: "Procuradores e advogados públicos" },
  { id: "259", name: "Produtores agrícolas em Geral" },
  { id: "260", name: "Produtores agropecuários em geral" },
  { id: "261", name: "Produtores da avicultura e cunicultura" },
  { id: "262", name: "Produtores de animais e insetos úteis" },
  { id: "263", name: "Produtores de espetáculos" },
  { id: "264", name: "Produtores de plantas medicinais" },
  { id: "265", name: "Produtores em pecuária em Geral" },
  { id: "266", name: "Professores de educação infantil" },
  { id: "267", name: "Professores do ensino médio" },
  { id: "268", name: "Professores do Ensino Profissional" },
  { id: "269", name: "Professores Ensino Fundamental" },
  { id: "270", name: "Professores Superior" },
  { id: "271", name: "Profissionais análise geográfica" },
  { id: "272", name: "Profissionais da confecção de roupas" },
  { id: "273", name: "Profissionais da escrita" },
  { id: "274", name: "Profissionais da informação" },
  { id: "275", name: "Profissionais da matemática" },
  { id: "276", name: "Profissionais das ciências" },
  { id: "277", name: "Profissionais de estatística" },
  { id: "278", name: "Profissionais de recursos humanos" },
  { id: "279", name: "Profissionais do jornalismo" },
  { id: "280", name: "Profissionais do sexo" },
  { id: "281", name: "Psicólogos e psicanalistas" },
  { id: "282", name: "Químicos" },
  { id: "283", name: "Recepcionistas" },
  { id: "284", name: "Recreadores" },
  { id: "285", name: "Relações públicas e publicidade" },
  { id: "286", name: "Reparadores aparelhos eletrodomésticos" },
  { id: "287", name: "Reparadores equipamentos de escritório" },
  { id: "288", name: "Reparadores equipamentos fotográficos" },
  { id: "289", name: "Representantes comerciais autônomos" },
  { id: "290", name: "Restauradores instrumentos musicais" },
  { id: "291", name: "Revestidores de concreto" },
  { id: "292", name: "Secretárias executivas e bilíngües" },
  { id: "293", name: "Serventuários da justiça e afins" },
  { id: "294", name: "Serviços bancários" },
  { id: "295", name: "Serviços de coleta de resíduos" },
  { id: "296", name: "Serviços de embelezamento e higiene" },
  { id: "297", name: "Serviços de manutenção de edificações" },
  { id: "298", name: "Serviços veterinários" },
  { id: "299", name: "Soldagem e corte de ligas metálicas" },
  { id: "300", name: "Sopradores, moldadores de vidros" },
  { id: "301", name: "Subtenentes e sargentos PM" },
  { id: "302", name: "Superfícies de metais e de compósitos" },
  { id: "303", name: "Supervisores administrativos" },
  { id: "304", name: "Supervisores atendimento ao público" },
  { id: "305", name: "Supervisores da construção civil" },
  { id: "306", name: "Supervisores da extração mineral" },
  { id: "307", name: "Supervisores da mecânica de precisão" },
  { id: "308", name: "Supervisores da produção de utilidades" },
  { id: "309", name: "Supervisores das artes gráficas" },
  { id: "310", name: "Supervisores de confecção" },
  { id: "311", name: "Supervisores de indústrias" },
  { id: "312", name: "Supervisores de joalheria e afins" },
  { id: "313", name: "Supervisores de serviços financeiros" },
  { id: "314", name: "Supervisores de usinagem" },
  { id: "315", name: "Supervisores de vendas" },
  { id: "316", name: "Supervisores de vidraria e cerâmica" },
  { id: "317", name: "Supervisores dos serviços" },
  { id: "318", name: "Supervisores embalagem e etiquetagem" },
  { id: "319", name: "Supervisores em serviços de reparação" },
  { id: "320", name: "Supervisores exploração agropecuária" },
  { id: "321", name: "Supervisores fabricação alimentos" },
  { id: "322", name: "Supervisores fabricação bebidas e fumo" },
  { id: "323", name: "Supervisores fabricação celulose" },
  { id: "324", name: "Supervisores fabricação metalmecânica" },
  { id: "325", name: "Supervisores indústria madeira e mobiliário" },
  { id: "326", name: "Supervisores indústrias siderúrgicas" },
  { id: "327", name: "Supervisores manutenção de máquinas" },
  { id: "328", name: "Supervisores materiais construção" },
  { id: "329", name: "Supervisores na área florestal" },
  { id: "330", name: "Tabeliães e registradores" },
  { id: "331", name: "Técnico em farmácia" },
  { id: "332", name: "Técnicos agrícolas" },
  { id: "333", name: "Técnicos calibração e instrumentação" },
  { id: "334", name: "Técnicos de apoio à bioengenharia" },
  { id: "335", name: "Técnicos de apoio à biotecnologia" },
  { id: "336", name: "Técnicos de controle da produção" },
  { id: "337", name: "Técnicos de imobilizações ortopédicas" },
  { id: "338", name: "Técnicos de laboratório industrial" },
  { id: "339", name: "Técnicos de odontologia" },
  { id: "340", name: "Técnicos de planejamento de produção" },
  { id: "341", name: "Técnicos de produção de indústrias" },
  { id: "342", name: "Técnicos de seguros e afins" },
  { id: "343", name: "Técnicos Desenvolvimento sistemas" },
  { id: "344", name: "Técnicos de vendas especializadas" },
  { id: "345", name: "Técnicos do mobiliário e afins" },
  { id: "346", name: "Técnicos e auxiliares de enfermagem" },
  { id: "347", name: "Técnicos eletricidade e eletrotécnica" },
  { id: "348", name: "Técnicos em administração" },
  { id: "349", name: "Técnicos em aquicultura" },
  { id: "350", name: "Técnicos em artes gráficas" },
  { id: "351", name: "Técnicos em áudio" },
  { id: "352", name: "Técnicos em biblioteconomia" },
  { id: "353", name: "Técnicos em biologia" },
  { id: "354", name: "Técnicos em cenografia" },
  { id: "355", name: "Técnicos em cerâmicos e vidros" },
  { id: "356", name: "Técnicos em computadores" },
  { id: "357", name: "Técnicos em construção civil" },
  { id: "358", name: "Técnicos em contabilidade" },
  { id: "359", name: "Técnicos em controle ambiental" },
  { id: "360", name: "Técnicos em eletromecânica" },
  { id: "361", name: "Técnicos em eletrônica" },
  { id: "362", name: "Técnicos em equipamentos médicos" },
  { id: "363", name: "Técnicos em fotônica" },
  { id: "364", name: "Técnicos em geologia" },
  { id: "365", name: "Técnicos em geomática" },
  { id: "366", name: "Técnicos em logística de transportes" },
  { id: "367", name: "Técnicos em mecatrônica" },
  { id: "368", name: "Técnicos em metalurgia" },
  { id: "369", name: "Técnicos em mineração" },
  { id: "370", name: "Técnicos em museologia e afins" },
  { id: "371", name: "Técnicos em necrópsia e taxidermistas" },
  { id: "372", name: "Técnicos em óptica e optometria" },
  { id: "373", name: "Técnicos em pecuária" },
  { id: "374", name: "Técnicos em produção de alimentos" },
  { id: "375", name: "Técnicos em próteses ortopédicas" },
  { id: "376", name: "Técnicos em secretariado, taquígrafos" },
  { id: "377", name: "Técnicos em segurança no trabalho" },
  { id: "378", name: "Técnicos em siderurgia" },
  { id: "379", name: "Técnicos em telecomunicações" },
  { id: "380", name: "Técnicos em terapias complementares" },
  { id: "381", name: "Técnicos em transportes aéreos" },
  { id: "382", name: "Técnicos em transportes rodoviários" },
  { id: "383", name: "Técnicos em tratamento de efluentes" },
  { id: "384", name: "Técnicos em turismo" },
  { id: "385", name: "Técnicos estenotipistas" },
  { id: "386", name: "Técnicos filme e vídeo" },
  { id: "387", name: "Técnicos florestais" },
  { id: "388", name: "Técnicos marítimos, fluviários" },
  { id: "389", name: "Técnicos mecânicos" },
  { id: "390", name: "Técnicos op. aparelhos de projeção" },
  { id: "391", name: "Técnicos op. de emissoras de rádio" },
  { id: "392", name: "Técnicos op. sistemas de televisão" },
  { id: "393", name: "Técnicos operações bancários" },
  { id: "394", name: "Técnicos patologia clínica" },
  { id: "395", name: "Técnicos pesquisa e desenvolvimento" },
  { id: "396", name: "Técnicos químicos" },
  { id: "397", name: "Técnicos têxteis" },
  { id: "398", name: "Técnicos transportes metroferroviários" },
  { id: "399", name: "Técnicos transportes vias navegáveis" },
  { id: "400", name: "Telhadores" },
  { id: "401", name: "Tenentes da polícia militar" },
  { id: "402", name: "Tenentes do corpo de bombeiros" },
  { id: "403", name: "Terapeutas ocupacionais e afins" },
  { id: "404", name: "Tintureiros, lavadeiros e afins" },
  { id: "405", name: "Trabalhadores artesanais" },
  { id: "406", name: "Trabalhadores beneficiamento do sal" },
  { id: "407", name: "Trabalhadores caldeiraria e serralheria" },
  { id: "408", name: "Trabalhadores cargas de mercadorias" },
  { id: "409", name: "Trabalhadores da impressão gráfica" },
  { id: "410", name: "Trabalhadores das indústrias têxteis" },
  { id: "411", name: "Trabalhadores de apoio à pesca" },
  { id: "412", name: "Trabalhadores de fibras têxteis" },
  { id: "413", name: "Trabalhadores de forjamento metais" },
  { id: "414", name: "Trabalhadores de segurança" },
  { id: "415", name: "Trabalhadores de tecelagem manual" },
  { id: "416", name: "Trabalhadores de trefilação metais" },
  { id: "417", name: "Trabalhadores de vidros planos" },
  { id: "418", name: "Trabalhadores do acabamento gráfico" },
  { id: "419", name: "Trabalhadores dos serviços funerários" },
  { id: "420", name: "Trabalhadores estruturas de alvenaria" },
  { id: "421", name: "Trabalhadores fabricação de açúcar" },
  { id: "422", name: "Trabalhadores florestais polivalentes" },
  { id: "423", name: "Trabalhadores Fotográfico e radiológico" },
  { id: "424", name: "Trabalhadores fundição de metais" },
  { id: "425", name: "Trabalhadores indústria do fumo" },
  { id: "426", name: "Trabalhadores moldagem de metais" },
  { id: "427", name: "Trabalhadores serviços domésticos" },
  { id: "428", name: "Trabalhadores subaquáticos" },
  { id: "429", name: "Trabalhadores tingimento e estamparia" },
  { id: "430", name: "Trabalhadores tipográficos linotipistas" },
  { id: "431", name: "Trançadores e laceiros de cabos de aço" },
  { id: "432", name: "Tratadores polivalentes de animais" },
  { id: "433", name: "Tratamento e distribuição de água" },
  { id: "434", name: "Tratamento e preparação da madeira" },
  { id: "435", name: "Tratamento térmico de metais" },
  { id: "436", name: "Vendedores" },
  { id: "437", name: "Veterinários e zootecnistas" },
  { id: "438", name: "Vidraceiros" },
  { id: "439", name: "Vigilantes e guardas de segurança" },
];

export const optionDate = [
  { id: "5", name: "5" },
  { id: "10", name: "10" },
  { id: "15", name: "15" },
];

export const optionFlowType = [
  { id: "Linear", name: "Linear" },
  { id: "Escalonado", name: "Escalonado" },
];

export const optionCloseLose = [
  { id: "1", name: "Desistência" },
  { id: "2", name: "Proposta Concorrência" },
  { id: "3", name: "Financiamento Condicionado" },
  { id: "4", name: "Financiamento Reprovado" },
  { id: "5", name: "Restrição" },
  { id: "6", name: "Erro de cadastro" },
  { id: "7", name: "SLA" },
  { id: "8", name: "Seguiu com outra oportunidade" },
];
